import { API, graphqlOperation } from "aws-amplify";
import * as queries from './../graphql/queries';
import * as mutations from './../graphql/mutations';
import { IPartnerDTO } from '../models/partner';
import loggerService from './loggerService';

export default class PartnerService {

	static async GetOrCreatePartner(partnerDTO: IPartnerDTO) {		
		let partner = await this.GetBySub(partnerDTO.sub);
		if (!partner) {
			let id = await this.insertPartner(partnerDTO);
			if (!id)
				return;
			partnerDTO.id = id;
			partner = partnerDTO;
		}
		return partner;
	}

    static async GetBySub(sub: string)  {		
		let result: any;
		try {
			 result = await API.graphql(
				graphqlOperation(
					queries.partnerBySub, 
					{sub: sub}
					)
				);
		}
		catch(err) {
			loggerService.logError(err);
			return;
		}			
		if (result && result.data && result.data.PartnerBySub && result.data.PartnerBySub.items && result.data.PartnerBySub.items.length===1) {
			const partner:IPartnerDTO = {
				id : result.data.PartnerBySub.items[0].id,
				sub : result.data.PartnerBySub.items[0].sub,
				type : result.data.PartnerBySub.items[0].type,
				name: result.data.PartnerBySub.items[0].name,
				email: result.data.PartnerBySub.items[0].email,
				rewardPercentage : result.data.PartnerBySub.items[0].rewardPercentage,
				clientDiscount : result.data.PartnerBySub.items[0].clientDiscount,
				clientBonus : result.data.PartnerBySub.items[0].clientBonus
			}
			return partner;
		}
		return;
    }

	static async insertPartner(partnerDTO: IPartnerDTO) {
		let iPartner: any;
		try {
			iPartner = await API.graphql(graphqlOperation(mutations.createPartner, {input: partnerDTO}));
			return(iPartner);

		} catch(err) {
			loggerService.logError(err, 'Erro inserindo partner');
			alert('Erro inserindo partner: ' + loggerService.sanitizeError(err));
			return(null);
		}
	}

	private validatePartner(partnerDTO: IPartnerDTO) {

	}

	static async GetByEmail(email: string)  {		
		let result: any;
		try {
			 result = await API.graphql(
				graphqlOperation(
					queries.getPartner, 
					{email: email}
					)
				);
		}
		catch(err) {
			loggerService.logError(err);
			return;
		}			
		if (result && result.data && result.data.PartnerBySub && result.data.PartnerBySub.items && result.data.PartnerBySub.items.length===1) {
			const partner:IPartnerDTO = {
				id : result.data.PartnerBySub.items[0].id,
				sub : result.data.PartnerBySub.items[0].sub,
				type : result.data.PartnerBySub.items[0].type,
				name: result.data.PartnerBySub.items[0].name,
				email: result.data.PartnerBySub.items[0].email,
				rewardPercentage : result.data.PartnerBySub.items[0].rewardPercentage,
				clientDiscount : result.data.PartnerBySub.items[0].clientDiscount,
				clientBonus : result.data.PartnerBySub.items[0].clientBonus
			}
			return partner;
		}
		return;
    }

    // GetById(PartnerId: string) {
    
    // }
    
    // GetAll() {
    
    // }
    
    // Add(partnerDTO: IPartnerDTO) {
    
    // }
    
    // Update(partnerDTO: IPartnerDTO) {
    
    // }
    
    // Delete(partnerId: string) {
    
    // }
}

