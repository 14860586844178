import React, { Component}  from "react";
import { Form } from 'react-bootstrap';
import LoaderButton from './../components/LoaderButton';
import { API, graphqlOperation } from "aws-amplify";
import './Payment.css';
import * as queries from './../graphql/queries.ts';
import * as mutations from './../graphql/mutations.ts';
import {maxlength} from '../logic/myfunctions.js';
import NavLink from "react-bootstrap/NavLink";
import { formatarData} from '../logic/myfunctions.js';



class Payment extends Component {
      parceiros = null;
      mensagem = '';
      mensagemSucesso = '';
      hoje = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2) + '-' + ('0' + new Date().getDate()).slice(-2);      

      constructor(props) {
        super(props);

        var dateOffset = (24*60*60*1000) * 90; //90 days
        var DefaultIntervalStart = new Date();
        DefaultIntervalStart.setTime(DefaultIntervalStart.getTime() - dateOffset);
        DefaultIntervalStart = DefaultIntervalStart.getFullYear() + '-' + ('0' + (DefaultIntervalStart.getMonth() + 1)).slice(-2) + '-' + ('0' + DefaultIntervalStart.getDate()).slice(-2);
  
        this.state = {
          PartnerPayments: [],
          cmbParceiro:0,
          dtIntervalStart: DefaultIntervalStart,
          dtIntervalEnd: this.hoje,
          total: 0
        };
      }

      
      async componentDidUpdate(prevProps, prevState,snapshot) {
        if (this.props.userId) {
          //access only for administrator
          if (this.props.userType==='IntAd') {
            if (!this.state.autorizado) {
              this.setState({autenticado:true, autorizado: true});
            }
            try {
                //a acesso a dados de outros parceiros será filtrado no carregamento do combo
                //this.montaComboParceiro();
                // lista vendas do parceiro (apenas se foi alterado parceiro escolhido no combo)
               if (this.state.dtIntervalStart!==prevState.dtIntervalStart || this.state.dtIntervalEnd!==prevState.dtIntervalEnd) {
                   //seleciona pagamentos do parceiro
                   this.listPayments(this.state.dtIntervalStart, this.state.dtIntervalEnd).then(mypayments => {   
                      this.setState({ PartnerPayments:mypayments, total:this.calculaTotal(mypayments) });                    
  //                  console.log(this.state.PartnerPayments);
                    }).catch(err => console.log(err));
               }          
              
            } catch (e) {
              console.log(e);
            }
          } else {
            if (!this.state.autenticado) {
              this.setState({autenticado: true, autorizado: false});
            }
          }
        }
      }    
    
      async componentDidMount () {
        this.listPayments(this.state.dtIntervalStart, this.state.dtIntervalEnd).then(mypayments => {   
            this.setState({ PartnerPayments:mypayments, total:this.calculaTotal(mypayments) });  
        }).catch(err => console.log(err));
      }

      calculaTotal(inputPayments) {
        //recalcula valor a pagar
        var total = 0.00;
        inputPayments.forEach(e => {total += parseFloat(e.paymentAmount);});
        return(parseFloat(total).toFixed(2));
       }

      changeHandler = event => {
//        console.log(event.target.id);
        this.setState({ [event.target.id]: event.target.value });
      };
    
      handleDelete = event => {
          console.log(event.target);
          if (window.confirm('Tem certeza que quer apagar este item? (' + event.target.name + ')'))
              if (this.deletePayment(event.target.name))
                console.log('Item excluído com sucesso.');
              else
                console.log('Ooops, verifique se o item foi excluído');
      }
    
      listPayment = option => (
        <tr key={option.id}>
            <td>{formatarData(option.date)}</td>
            <td>{option.paymentAmount}</td>
            <td>{maxlength((option.partner.name || option.partner.email),12)}</td>
            <td><LoaderButton 
                        name={option.id}
                        type="button"
                        variant="btn btn-secondary btn-sm"
                        text="Excluir"
                        onClick={this.handleDelete}
                      >
                </LoaderButton>
            </td>
        </tr>
      );
        
      mountPayments = () => this.state.PartnerPayments.map(this.listPayment);
      
/*
      montaComboParceiro = () => {   
        var x = document.getElementById("cmbParceiro");
        if (this.props.userType==='IntAd') {
          this.listPartners().then(parceiros => {
              this.parceiros = parceiros;
              if (document.getElementById("cmbParceiro").length<=1)
              {
                //ordenando parceiros
                this.parceiros.sort(function(a, b) {
                      if(a === null){
                        return 1;
                      }
                      else if(b === null){
                        return -1;
                      }
                      var nameA = (a.name || a.email).toUpperCase(); // ignore upper and lowercase
                      var nameB = (b.name || b.email).toUpperCase(); // ignore upper and lowercase
                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }
                      // names must be equal
                      return 0;
                    });
    
                for (var i=0;i<this.parceiros.length;i++) {
                  if (this.parceiros[i].type==='Ext') {
                    var option = document.createElement("option");
                    option.text = (this.parceiros[i].name || this.parceiros[i].email); // + ' (' + (this.parceiros[i].name || "sem nome") + ')';
                    option.value = this.parceiros[i].id;
                    x.add(option);
                  }
                }
              }
          }).catch(err => {console.log(err);});
        }
      }
    
      async listPartners () {
        const myPartners = await API.graphql(graphqlOperation(queries.listPartners, {limit: 1000}));
        return(myPartners.data.listPartners.items);
      }
*/

      async listPayments (dtIntervalStart, dtIntervalEnd) {
        var myData = await API.graphql({
            query: queries.listPayments,
            variables: { filter: 
                    {date: {between : [dtIntervalStart, dtIntervalEnd]}
                  }, limit: 100 },
            authMode: 'AWS_IAM'
          }); 
        return(myData.data.listPayments.items);
      }

      async deletePayment (idPayment) {
        try {
            var result = false;
            //seleciona os idPaymentOrder e apaga            
//            console.log(this.state.PartnerPayments[0].orders.items);
            if (await this.deletePaymentOrderItems(this.state.PartnerPayments[0].orders.items)){
//                console.log('Apagando Payment: ' + idPayment);              
                const myInput = {id: idPayment};  
                result = await API.graphql(graphqlOperation(mutations.deletePayment, {input: myInput}));
                this.listPayments(this.state.dtIntervalStart, this.state.dtIntervalEnd).then(mypayments => {   
                    this.setState({ PartnerPayments:mypayments, total:this.calculaTotal(mypayments) });  
                }).catch(err => console.log(err));        
                return(result);
            }
        } catch(err) { console.log(err);}
      }

      async deletePaymentOrderItems (idPaymentOrders) {
        try {
            var result;
            for (var i=0;i<idPaymentOrders.length;i++) {
//                console.log('Apagando PaymentOrder: ' + idPaymentOrders[i].id);
                const myInput = {id: idPaymentOrders[i].id};  
                result = await API.graphql(graphqlOperation(mutations.deletePaymentOrders, {input: myInput}));
                console.log(result);
            }
            return(true);
        } catch(err) { console.log(err);return(false);}
      }

      
      renderDetalhe() {
/*
                  <FormGroup controlId="cmbParceiro">
                    <FormControl
                      required
                      pattern="[0-9a-f]{8}[-][0-9a-f]{4}[-][0-9a-f]{4}[-][0-9a-f]{4}[-][0-9a-f]{12}"
                      as="select"
                      size="lg"
                      placeholder="Parceiro"
                      value={this.state.cmbParceiro}
                      onChange={this.changeHandler}
                    >
                      <option value="0">Selecione o parceiro</option>
                    </FormControl>
                  </FormGroup>                    

  */
       return(
          <div className="Payment">
            <h3>Pagamentos</h3>
            <div>
              <Form className="PaymentFiltro"
                    noValidate
                    id="frmPayment"
                    >
                  <div>
                    <NavLink 
                        className="btn btn-secondary"
                        href="/newpayment"
                      >Novo(+)
                      </NavLink>
                  </div>
                  <div className="PaymentFiltro">
                        <div className="PaymentLinha">
                            <div className="PaymentBloco"><label>Período de: </label></div>
                            <div className="PaymentBloco"><input type="date" id="dtIntervalStart" value={this.state.dtIntervalStart} onChange={this.changeHandler} ></input></div>
                        </div>
                        <div className="PaymentLinha">
                            <div className="PaymentBloco"><label>Até: </label></div>
                            <div className="PaymentBloco"><input type="date" id="dtIntervalEnd" value={this.state.dtIntervalEnd} onChange={this.changeHandler} ></input></div>
                        </div>
                  </div>
                  <div>
                      <table className="PaymentTabela">
                        <tbody>
                          <tr>
                            <th>Data</th>
                            <th>Valor</th>
                            <th>Parceiro</th>
                            <th>Ação</th>
                          </tr>
                          {this.mountPayments()}
                          <tr>
                              <td>Total</td>
                              <td colSpan="3">
                                  <input type="text" value={this.state.total} readOnly className="PaymentTotal"></input>                                            
                             </td>
                          </tr>
                        </tbody>
                      </table>
                  </div>
                  <div className="form-group mt-2">
                   </div>
              </Form>
             </div>
          </div>
        );
      }


      render () {
          if (this.state.autorizado) {
            return(this.renderDetalhe());
          }
          else { //não autorizado
            if (!this.state.autenticado)
              this.mensagem = 'Verificando credenciais do usuário, aguarde...';
            else
              this.mensagem = "Acesso negado para usuário.";
            return(<div className="Payment"><p>{this.mensagem}</p></div>);
          }
      }
    
    }
    export default Payment;
    