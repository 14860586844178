import React from 'react';
import { Container, Row, Col } from 'react-bootstrap';
import './About.css';
const package_json = require('./../../package.json');

const About = () => {


    const versao = package_json.version; 

    return(
        <Container >
            <Row className="about">
                <Col>
                    <h1>Indicação de Cintas Modeladoras</h1>
                    <p>Este <i>app</i> é recomendado para clínicas, profissionais de saúde (cirurgiões plásticos, ginecologistas, medicina estética, cirurgião vasculares, fisioterapeutas, nutricionistas, esteticistas, instrumentadoras, etc ), bem como estilistas e outros profissionais que recomendam o uso de cintas modeladoras para potencializar resultados com produtos de altíssima qualidade, benefícios exclusivos para seus pacientes/clientes sempre com atendimento especializado e tranquilidade no pós-venda.</p>
                    <p><a className="about" target="_blank" rel="noopener noreferrer" href="https://www.minhacintamodeladora.com.br/">Loja virtual Minha Cinta Modeladora</a></p>
                    <p><a className="about" target="_blank" rel="noopener noreferrer" href="https://www.minhacintamodeladora.com.br/Institucional/lojas-fisicas">Nossas Lojas Físicas</a></p>
                    <h3>Mais informações ?</h3>
                    <p>Por email: <a className="about" href="mailto:atendimento@minhacintamodeladora.com.br">atendimento@minhacintamodeladora.com.br</a></p>
                    <p>Pelo Whatsapp: <a className="about" href="http://api.whatsapp.com/send?1=pt_BR&amp;phone=551143015669"><img src="https://desconto.minhacintamodeladora.com.br/img/whatsapp.png" alt="Icone do Whatsapp" width="32px" /> 11 4301-5669</a></p>
                    <p>Instagram: <a  className="about" target="_blank" rel="noopener noreferrer" href="https://www.instagram.com/minhacintamodeladora">@MinhaCintaModeladora</a></p>

                    <h3>Versão: {versao}</h3>
                    <p>Créditos: desenvolvido por Santa Cinta EIRELI</p>

                </Col>
            </Row>
        </Container>

);
}

export default About;
