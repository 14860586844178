import React, { Component}  from "react";
import LoaderButton from './../components/LoaderButton';
import { Form, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import { API, graphqlOperation } from "aws-amplify";
import './MySale.css';
import MyReferralDetail from '../components/MyReferralDetail';
import * as queries from './../graphql/queries.ts';
import * as mutations from './../graphql/mutations.ts';
import QrReader from 'react-qr-reader';
import Subscriptions from '../logic/Subscriptions.js';
import CustomDropdown from './../components/CustomDropdown';

class MySale extends Component {
  parceiros = null;
  stores = null;
  myTimer = null;
  mensagem = '';
  mensagemSucesso = '';
  contador = 0;
  partnerLoaded = false;

  constructor(props) {
    super(props);

    var _autenticado = false;
    var _autorizado = false;

    if (this.props.userSub) {
       _autenticado = true;
       if (this.props.userType.substring(0,3)==='Int')
        _autorizado = true;    
    }

    this.state = {
      autenticado: _autenticado,
      autorizado: _autorizado,
      nomeCliente: '',
      documento: '',
      vlrDocumento: '',
      cmbLoja: '',
      vendedora:  '',
      obsVenda: '',
      result: null,
      descontoUtilizado: false,
      rewardPercentage: 0,
      dataVenda: new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2) + '-' + ('0' + new Date().getDate()).slice(-2),
      partnerId: ''
//      result: '8ae44050-af09-4cab-8682-6f53961f3a95'
      }
      this.setRewardPercentage = this.setRewardPercentage.bind(this);

      if (!this.partnerLoaded)
        this.fillPartners();      
  }


  componentDidUpdate() {
    if (!this.partnerLoaded)
      this.fillPartners();

    try {
      if (document.getElementById("cmbLoja")) {
        if (document.getElementById("cmbLoja").length<=1) {
          this.montaComboLoja();
          document.getElementById("cmbLoja").setCustomValidity('Loja inválida!');
        }
      }
      if (document.getElementById("vendedora") && this.stores) {
        this.atualizaComboVendedoras();
      }
    } catch (e) {
      console.log(e);
    }
  }

  handleScan = data => {
    if (data) {
      //get and save the PartnerId
      this.getPartnerId(data)
      .then(PartnerId => {
          this.setState({
            result: data,
            partnerId: PartnerId
          });
          if (document.getElementById("leitorQR") && this.result)
          document.getElementById("leitorQR").style.display='none';    
      })
      .catch(err => {
          console.log(err);
      })
    }
  }

  handleError = err => {
    console.error(err)
  }

  handleClick = nada => {
    var idParceiro = document.getElementById("cmbParceiro").value;
    if (idParceiro) {
      //insere uma indicação automática para parceiro selecionado
      this.saveReferralInBehalfOf(idParceiro, 'Indicação criada pelo funcionário '+this.props.userEmail).then(idIndicacao => {
        this.setState({
          result: idIndicacao,
          partnerId: idParceiro
        });
        if (document.getElementById("leitorQR") && this.result)
          document.getElementById("leitorQR").style.display='none';
        }).catch(err => {console.log(err);});
    }
  }

  changeHandler = event => {    
    this.setState({ [event.target.id]: event.target.value });
  };


  onBlurHandler = event => {
    if (document.getElementById("cmbLoja").value==="0") {
      document.getElementById("cmbLoja").setCustomValidity('Loja inválida!');
    }
    else {
      document.getElementById("cmbLoja").setCustomValidity('');
    }
  }

  montaComboLoja = () => {
    this.listStores2().then(lojas => {
        this.stores = lojas;
        var x = document.getElementById("cmbLoja");
        //for (;x.length>0;) {
        //  x.remove(x.length-1);
        //}
        if (document.getElementById("cmbLoja").length<=1)
        {
          for (var i=0;i<this.stores.length;i++) {
            var option = document.createElement("option");
            option.text = this.stores[i].name;
            option.value = this.stores[i].id;
            x.add(option);
          }
          this.atualizaComboVendedoras();
        }
    }).catch(err => {console.log(err);});
  }

  atualizaComboVendedoras = () => {
    if (document.getElementById("cmbLoja")) {
      var storeSelected = document.getElementById("cmbLoja").value;
      var x = document.getElementById("vendedora");
      for (;x.length>0;) {
        x.remove(x.length-1);
      }
      for (var i=0;i<this.stores.length;i++) {
        if (storeSelected===this.stores[i].id)
        {
          for(var j=0;j<this.stores[i].salesmans.items.length;j++) {
            var option = document.createElement("option");
            option.text = this.stores[i].salesmans.items[j].name;
            option.value = this.stores[i].salesmans.items[j].id;
            x.add(option);
          }
        }
      }
    }
  }

  async listStores2 () {
    const myStores = await API.graphql(graphqlOperation(queries.listStores2, {limit: 30}));
    return(myStores.data.listStores.items);
  }

  async getPartnerId (referralId) {
    const myReferral = await API.graphql({
      query: queries.getReferral2,
      variables: { id: referralId },
      authMode: 'AWS_IAM'
      });
      return(myReferral.data.idParceiro);
  }

  async listPartners () {
    const myPartners = await API.graphql(graphqlOperation(queries.listPartners, {limit: 1000}));
    return(myPartners.data.listPartners.items);
  }

  fillPartners = () => {
        this.listPartners().then(partners => {
        partners.sort(function(a, b) {
            if(a === null){
               return 1;
             }
             else if(b === null){
               return -1;
             }
              var nameA = (a.name || a.email).toUpperCase(); // ignore upper and lowercase
              var nameB = (b.name || b.email).toUpperCase(); // ignore upper and lowercase
              if (nameA < nameB) {
                return -1;
              }
              if (nameA > nameB) {
                return 1;
              }
              return 0;
            });
        this.setState({partnersList: partners
          .filter(p => p.type==='Ext' && (p.name && p.name.substring(0,2).toUpperCase()!=='X '))
          .map((p)=> ( {id:p.id, text:(p.name || p.email) + ' (' + 100 * p.clientDiscount + '%)'} ))});

        this.partnerLoaded = true;
        this.parceiros = partners;
      }
    );
  }
  
  montaComboParceiro = () => {
  }
  //   this.contador++;
  //   var x = document.getElementById("cmbParceiro");
  //   // if (!x)
  //   //   return;
  //   if (this.props.userType==='Ext') {
  //     if (document.getElementById("cmbParceiro").length<=1)
  //     {
  //       var option = document.createElement("option");
  //       option.text = this.props.userEmail;
  //       option.value = this.props.userId;
  //       x.add(option);
  //     }
  //   }
  //   else {
  //     this.listPartners().then(parceiros => {
  //         this.parceiros = parceiros;
  //         if (document.getElementById("cmbParceiro").length<=1)
  //         {
  //           //ordenando parceiros
  //           this.parceiros.sort(function(a, b) {
  //               if(a === null){
  //                  return 1;
  //                }
  //                else if(b === null){
  //                  return -1;
  //                }
  //                 var nameA = (a.name || a.email).toUpperCase(); // ignore upper and lowercase
  //                 var nameB = (b.name || b.email).toUpperCase(); // ignore upper and lowercase
  //                 if (nameA < nameB) {
  //                   return -1;
  //                 }
  //                 if (nameA > nameB) {
  //                   return 1;
  //                 }
  //                 return 0;
  //               });

  //           for (var i=0;i<this.parceiros.length;i++) {
  //             if (this.parceiros[i].type==='Ext') {
  //               var option = document.createElement("option");

  //               option.text = (this.parceiros[i].name || this.parceiros[i].email) + ' (' + 100*this.parceiros[i].clientDiscount + '%)';// + ' (' + (this.parceiros[i].name || "sem nome") + ')';
  //               option.value = this.parceiros[i].id;
  //               if (x)
  //                 x.add(option);
  //             }
  //           }
  //         }
  //     }).catch(err => {console.log(err);});
  //   }
  // }

  destacaErr(obj) {
    obj.style.borderWidth = "thick";
    obj.style.borderColor = "red";
  }

  removeDestacaErr(obj) {
    obj.style.borderWidth = "";
    obj.style.borderColor = "";
  }

  validaDadosVenda = (msg) => {
     var validado = true;
     msg.text = '';

     return(validado);
  }

  submitHandler = event => {
    event.preventDefault();
    event.target.className += " was-validated";
    if (event.target.checkValidity()) {
      this.createOrder().then(retorno => {
        this.mensagemSucesso='Venda registrada com sucesso!';
        this.zeraVenda();
        this.myTimer  = setInterval(this.limpaMsg, 10000);
      }).catch(err => {alert('Erro registrando venda.  [' + err.message + ']');});
    }
  }

  limpaMsg = () => {
    clearInterval(this.myTimer);
    this.mensagemSucesso='';
    document.getElementById("msgSucesso").innerHTML = '';
  }

  createOrder = async () => {
    var msg={text:''};
    document.getElementById("msgerro").innerHTML = '';
    if (!this.validaDadosVenda(msg))
    {
      document.getElementById("msgerro").innerHTML = msg.text;
    } else {
      //insere Order
      var saleDate = new Date().toISOString();
      if (document.getElementById("dataVenda")) 
        saleDate = document.getElementById("dataVenda").value


      const myOrder = {
        date: saleDate,
        clientName: document.getElementById("nomeCliente").value,
        document: document.getElementById("documento").value,
        amount: document.getElementById("vlrDocumento").value.replace(',','.'),
        obs: document.getElementById("obsVenda").value ? document.getElementById("obsVenda").value : 'N/D',
        orderReferralId: this.state.result,
        orderStoreId:document.getElementById("cmbLoja").value,
        orderSalesmanId:document.getElementById("vendedora").value,
        clientPhone: 'N/D',
        clientCPF: (document.getElementById("cpfCliente").value==='' ? 'N/D' : document.getElementById("cpfCliente").value ),
        clientBirthday: 'N/D',
        rewardAmount: (this.state.rewardPercentage * document.getElementById("vlrDocumento").value.replace(',','.'))
      }

      var newOrder;
      var orderId;
      newOrder = await API.graphql(graphqlOperation(mutations.createOrder, {input: myOrder}));
      orderId = newOrder.data.createOrder.id;      
      const myInputReferralId = {id: this.state.result,
                  referralOrderId: orderId};
      await API.graphql(graphqlOperation(mutations.updateReferral, {input: myInputReferralId}));      

      const newNotification = new Subscriptions();
      newNotification.notifyUser(
          this.state.partnerId,
          'Nova venda realizada!',
          'Cliente: ' + myOrder.clientName
      );
    } 
  }



  setRewardPercentage = (RewardPercentage) => {    
    this.setState({rewardPercentage: RewardPercentage});
  }

  toogleDesconto = (valor) => {
    if (this.state.descontoUtilizado !== valor)
      this.setState({descontoUtilizado: valor});
  }

  async saveReferralInBehalfOf(PartnerId, obs) {
    try {
      var achou=false;
      for (var i=0;i<this.parceiros.length;i++)
      {
        if (this.parceiros[i].id===PartnerId)
        {
          achou=true;
          break;
        }
      }
      if (achou)
      {
        const myReferral = {
          date: new Date().toISOString(),
          referralPartnerId: PartnerId,
          clientName: 'N/D',
          clientPhone: 'N/D',
          linha: 'N/D',
          obs: (obs) ? obs : 'N/D',
          clientDiscount: this.parceiros[i].clientDiscount,
          rewardPercentage: this.parceiros[i].rewardPercentage,
          clientBonus: this.parceiros[i].clientBonus
        }

        var newReferral;
        newReferral = await API.graphql(graphqlOperation(mutations.createReferral, {input: myReferral}));

        return(newReferral.data.createReferral.id);
        }
    }
    catch (err) {
      console.log(err);
      return(err);
    }
  }

  renderDetalhe() {  
      try {
        if (document.getElementById("cmbLoja")) {
          if (document.getElementById("cmbLoja").length<=1) {
            this.montaComboLoja();
            document.getElementById("cmbLoja").setCustomValidity('Loja inválida!');
          }
        }
        if (document.getElementById("vendedora") && this.stores) {
          this.atualizaComboVendedoras();
        }
      } catch (e) {
        console.log(e);
      }
    
      return(
        <div className="mySaleContainer">
        <h2>Registro de Venda</h2>
        <div id="mainPlaceHolder">
          <MyReferralDetail referralId={this.state.result} descontoUtilizado={this.toogleDesconto} setRewardPercentage={this.setRewardPercentage}/>
        </div>
        <div>
          <section className="mySaleFormulario">
            {!this.state.descontoUtilizado ? (
            <Form className="needs-validation"
                  onSubmit={this.submitHandler}
                  noValidate
                  id="frmSale"
                  autoComplete="off"
                  >
              <FormGroup controlId="dataVenda">
                <FormLabel>Data da venda:</FormLabel>
                <FormControl
                  type="date"
                  size="lg"
                  value={this.state.dataVenda}
                  placeholder=""
                  className="form-control"
                  onChange={this.changeHandler}
                  onBlur={this.blurHandler}
                />
              </FormGroup>
              <FormGroup controlId="nomeCliente">
                <FormLabel>Nome do cliente:</FormLabel>
                <FormControl
                  required
                  type="text"
                  size="lg"
                  placeholder=""
                  className="form-control"
                  onChange={this.changeHandler}
                  onBlur={this.blurHandler}
                />
                <FormControl.Feedback type="invalid">Nome do cliente obrigatório</FormControl.Feedback>
              </FormGroup>
              <FormGroup controlId="cpfCliente">
                <FormLabel>CPF do cliente (somente números):</FormLabel>
                <FormControl
                  pattern="[0-9]{11}"
                  type="text"
                  size="lg"
                  placeholder=""
                  className="form-control"
                  onChange={this.changeHandler}
                  onBlur={this.blurHandler}
                />
              </FormGroup>
              <FormGroup controlId="documento">
                <FormLabel>Nro. do Documento (NFCe / NFe / ECF):</FormLabel>
                <FormControl
                  required
                  size="lg"
                  type="text"
                  className="form-control"
                  value={this.state.documento}
                  placeholder=""
                  onChange={this.changeHandler}
                />
                <FormControl.Feedback type="invalid">Nro. do documento obrigatório</FormControl.Feedback>
              </FormGroup>
              <FormGroup controlId="vlrDocumento">
                <FormLabel>Valor do Documento:</FormLabel>
                <FormControl
                  required
                  size="lg"
                  type="number"
                  className="form-control"
                  value={this.state.vlrDocumento}
                  onChange={this.changeHandler}
                  placeholder="0,00"
                />
                <FormControl.Feedback type="invalid">Vlr. do documento obrigatório</FormControl.Feedback>
              </FormGroup>
              <FormGroup controlId="cmbLoja">
                <FormLabel>Selecione a loja:</FormLabel>
                <FormControl
                   required
                   pattern="[0-9a-f]{8}[-][0-9a-f]{4}[-][0-9a-f]{4}[-][0-9a-f]{4}[-][0-9a-f]{12}"
                   as="select"
                   size="lg"
                   placeholder="Loja"
                   value={this.state.cmbLoja}
                   onChange={this.changeHandler}
                   onBlur={this.onBlurHandler}
                >
                  <option value="0">Selecione a loja</option>
                </FormControl>
                <FormControl.Feedback type="invalid">Loja obrigatória</FormControl.Feedback>
              </FormGroup>
              <FormGroup controlId="vendedora">
                <FormLabel>Selecione a vendedora:</FormLabel>
                <FormControl
                   as="select"
                   size="lg"
                   placeholder="Vendedora"
                   value={this.state.vendedora}
                   onChange={this.changeHandler}
                />
              </FormGroup>
              <FormGroup controlId="obsVenda">
                <FormLabel>Observação:</FormLabel>
                <FormControl
                  size="lg"
                  type="text"
                  className="form-control"
                  value={this.state.obsVenda}
                  onChange={this.changeHandler}
                  placeholder="Observação"
                />
              </FormGroup>
              <LoaderButton
                block
                variant="secondary"
                size="lg"
                type="submit"
                text="Registrar Venda"
              />
              <LoaderButton
                block
                variant="outline-secondary"
                size="lg"
                type="button"
                text="Cancelar"
                onClick={() => {
                  this.zeraVenda();
                }}
              />
              <span id="msgerro"></span>
              </Form>
            ) : (
              <p>Desconto já utilizado, não será possível vincular nova venda a este código de desconto.  Clique na opção Venda do menu para ler outro QRCode.</p>
            )}
          </section>
        </div>
        </div>
      );
  }

  zeraVenda = () => {
    document.getElementById('frmSale').reset();
    document.getElementById('nomeCliente').value='';
    document.getElementById('cpfCliente').value='';
    document.getElementById('documento').value='';
    document.getElementById('vlrDocumento').value='';
    document.getElementById('cmbLoja').value='0';
    document.getElementById('obsVenda').value='';
    this.setState({
        nomeCliente: '',
        cpfCliente: '',
        documento: '',
        vlrDocumento: '',
        cmbLoja: '',
        vendedora:  '',
        obsVenda: '',
        result: null,
        descontoUtilizado: false
      });
    if (document.getElementById('frmSale')) {
        document.getElementById('frmSale').reset();
    }
  }

  renderLeitor() {
    this.montaComboParceiro();

    return(
      <div className="mySaleContainer">
        <h2>Registro de Venda</h2>
        <p id="msgSucesso">{this.mensagemSucesso}</p>
        <p>Leia QRCode da Indicação do(a) Cliente</p>
          <QrReader
                  delay={300}
                  onError={this.handleError}
                  onScan={this.handleScan}
                  style={{ width: '100%'}}
        />
        <p>{this.state.result}</p>
        <div className="mySaleContainer">
          <div>&nbsp;</div>
          <p><b>OU</b></p>
          <p>Cliente foi indicado por um parceiro que não conseguiu gerar o QRCode</p>
          <p>Escolha o parceiro abaixo, verifique o DESCONTO, e prossiga para o registro da venda:</p>
          <div className="relSaleFormulario">
            <Form className="needs-validation"
                  noValidate
                  id="frmRelSale"
                  >
              <FormGroup controlId="cmbParceiro">
                 {/* <FormControl
                   required
                   pattern="[0-9a-f]{8}[-][0-9a-f]{4}[-][0-9a-f]{4}[-][0-9a-f]{4}[-][0-9a-f]{12}"
                   as="select"
                   size="lg"
                   placeholder="Parceiro"
                   value={this.state.cmbParceiro}
                   onChange={this.changeHandler}
                >
                  <option value="0">Selecione o parceiro</option>                  
                </FormControl>  */}
                <CustomDropdown                  
                  name="cmbParceiro"
                  options={this.state.partnersList ? this.state.partnersList : []}
                  label="Selecione o parceiro"
                  onChange={this.changeHandler}  
                  value={this.state.cmbParceiro}                
                  class="form-control form-control-lg"
                ></CustomDropdown>
                <LoaderButton
                  block
                  variant="outline-secondary"
                  size="lg"
                  type="button"
                  text="Registrar Venda"
                  disabled={!this.state.cmbParceiro || this.state.cmbParceiro==="Selecione o parceiro"}
                  onClick={() => {
                    this.handleClick();
                  }}
                />

              </FormGroup>
            </Form>
          </div>
        </div>

      </div>
    );
  }


  render () {
    if (this.state.autorizado)
      if (this.state.result)
        return(this.renderDetalhe());
      else {
        return(this.renderLeitor());
      }
    else { 
      this.mensagem = "Acesso negado.";
      return(<div className="mySaleContainer"><p>{this.mensagem}</p></div>);
    }
  }

}
export default MySale;
