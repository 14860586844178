import { API, graphqlOperation } from "aws-amplify";
import * as queries from './../graphql/queries';
import * as mutations from './../graphql/mutations';
import { products } from './../data/Products';
import loggerService from './loggerService';
import { IProductDTO, IProduct, mapIProductDTOToIProduct } from './../models/product';

export default class ProductService {

    static async listProducts(): Promise<Array<IProduct>> {
        const myProducts: any = await API.graphql(graphqlOperation(queries.listProducts, {limit: 500}));
        return(myProducts.data.listProducts.items);
    }

    static async insertNewProductsIntoDB() {
        var dbProducts: Array<IProduct> = await ProductService.listProducts();

        products.forEach(element => {            
            if (dbProducts.findIndex(p=> p.id === element.id)===-1)
                ProductService.insertProduct(element);            
        })
    }

    static async insertProduct(newProduct: IProductDTO) {
        var product: IProduct = mapIProductDTOToIProduct(newProduct);        
        let iProduct: any;
		try {
			iProduct = await API.graphql(graphqlOperation(mutations.createProduct, {input: product}));
            console.log('Inserted!  Update the ID in the products.js', product.reference, iProduct);
			return(iProduct);
  
		} catch(err) {
			loggerService.logError(err, 'Erro inserindo produto');
			alert('Erro inserindo produto: ' + loggerService.sanitizeError(err));
			return(null);
		}
    }

}

