import React, { Component }  from "react";
import MyReferral from './MyReferral';
import SelectedProducts from './SelectedProducts';
import "./MySwiper.css";
import {Carousel, Spinner} from 'react-bootstrap';

class MySwiper extends Component {
  ct_Indicacoes = 0;
  //array de objetos de produtos do catálogo
  indicacoes = [];

  constructor(props) {
    super(props);

    this.state = {
      currentIndex: 0,
      indicacoes: null,
      indicating: false
    };

    this.handleClick = this.handleClick.bind(this);
  }

  limpar = () => {
     this.indicacoes = [];
     this.ct_Indicacoes=0;
     this.setState({indicacoes:this.indicacoes,indicating:false});

    try {
     document.getElementById('btnLimpar').style.display = "none";
     document.getElementById('btnIndicar').style.display = "none";
     document.getElementById('dica').style.display = "inline";
     for (var i=0 ; i<4 ; i++) {
       document.getElementById('imgsel'+i).src='';
       document.getElementById('imgsel'+i).style.display = "none";
      }
    }
    catch {}  
  }

  reinicio = (mensagem) => {
    this.limpar();
    if (mensagem) {
      document.getElementById("dica").innerHTML = mensagem;
      this.myTimer  = setInterval(this.updtRef, 5000);
    }
  }

  updtRef = () => {
    document.getElementById("dica").innerHTML = 'Clique nos produtos que deseja Indicar';
  }


  handleClick(event) {
    if (this.props.userData.userId) {
      //somente usuário logados podem selecionar produtos
      if (this.ct_Indicacoes<4) {
        var jatem=false;
        for (var ct=0;ct<this.ct_Indicacoes;ct++) {
          if (this.indicacoes[ct].ref===this.props.imgs[this.state.currentIndex].ref) {
            jatem=true;
          }
        }

        if (!jatem) {
          this.indicacoes.push(this.props.imgs[this.state.currentIndex]);          
          //document.getElementById('imgsel'+this.ct_Indicacoes).src = event.currentTarget.currentSrc.substring(window.location.origin.length);
          document.getElementById('imgsel'+this.ct_Indicacoes).src = event.currentTarget.currentSrc;
          document.getElementById('dica').style.display = "none";
          document.getElementById('btnLimpar').style.display = "inline";
          document.getElementById('btnIndicar').style.display = "inline";
          document.getElementById('imgsel'+this.ct_Indicacoes).style.display = "inline";
          this.ct_Indicacoes++;

        }
      }
    }
  }


  renderDeslogado = () => {
    if (this.props.userData.isAuthenticating) 
        return(
          <div>
          <Spinner size="sm" type="grow" animation="border" variant="secondary" /><span id="dica">  Identificando usuário... </span>
          </div>
        );
    else
        return(
              <div>
              <span id="dica">Efetue o login no menu acima (opção Entrar)</span>
              </div>
        );
  }

  renderLogado = () => {
    return(
      <SelectedProducts indicacoes={this.indicacoes} showButton={true} callbackLimpar={this.limpar} callbackIndicar={this.Indicar} />
    );
  }

  Indicar = () => {
      this.setState({indicacoes: this.indicacoes, indicating: true});
  }


   componentDidUpdate = (prevProps) => {
       if (this.props.userData.userFilter !== prevProps.userData.userFilter)
       {
          this.setState({       currentIndex: 0
                });
      }
    }

  render() {
    const handleSelect = (selectedIndex, e) => {
      this.setState({currentIndex: selectedIndex});
    };
    if (this.state.indicating)
      return (
        <div className='myswipermodal'>
          <div className="modal_content">
            <div id="divReferral" className="container-fluid">
              <MyReferral userData={this.props.userData} produtos={this.state.indicacoes} reinicia={this.reinicio.bind(this)}/>   
        </div></div></div>
      )
    else
    {
      return (
        <div className="myswipermodal">
          <div className="modal_content">
            <div id="divProducts">
                <Carousel activeIndex={this.state.currentIndex} onSelect={handleSelect} touch={true} indicators={true}>
        
                { this.props.imgs.map((src, idx) => (                
                    <Carousel.Item key={'carouselitem'+idx}>
                      <img className="prod" key={src.path+idx} src={src.path} alt={src.ref} onClick={this.handleClick} />
                      <div id="legenda" className="legenda">
                          <span id="dsAtual" >{src.description}</span><br />
                          <img id="logomarca" alt="Logo Marca" src={src.logo} width="40px" /><span id="refAtual">{src.ref}</span>
                      </div>
                    </Carousel.Item>
                ))}
        
                </Carousel>
            </div>
          <div className="modal_footer">
            {this.props.userData.userId? this.renderLogado() : this.renderDeslogado() }            
          </div>
        </div>
      </div>
      );
    }
  
  }  
}



export default MySwiper;
