import React from 'react';
import { Switch, Route } from 'react-router-dom';
import AppliedRoute from './components/AppliedRoute';

import Home from './containers/Home';
import NotFound from './containers/NotFound';
import Login from './containers/Login';
import Signup from './containers/Signup';
import ForgotPassword from './containers/ForgotPassword';
import MySale from './containers/MySale';
import RelSale from './containers/RelSale';
import Payment from './containers/Payment';
import NewPayment from './containers/NewPayment';
import Partners from './containers/Partners';
import About from './containers/About';
import Config from './containers/Config.tsx';

export default ({ userData }) => (
	<Switch>
		<AppliedRoute path="/" exact component={Home} props={userData} />
		<AppliedRoute path="/login" exact component={Login} props={userData} />
		<AppliedRoute path="/signup" exact component={Signup} props={userData} />
		<AppliedRoute path="/forgotpassword" exact component={ForgotPassword} props={userData} />
		<AppliedRoute path="/sale" exact component={MySale} props={userData} />
		<AppliedRoute path="/relsale" exact component={RelSale} props={userData} />
		<AppliedRoute path="/payment" exact component={Payment} props={userData} />
		<AppliedRoute path="/partners" exact component={Partners} props={userData} />
		<AppliedRoute path="/about" exact component={About} props={userData} />
		<AppliedRoute path="/newpayment" exact component={NewPayment} props={userData} />
		<AppliedRoute path="/config" exact component={Config} props={userData} />
		{/* Finally, catch all unmatched routes */}
		<Route component={NotFound} />
	</Switch>
);
