import axios from 'axios';

export default class Subscriptions {

    // returns true or false
    notifyUser = async (partnerId, title, message) => {
        
        return fetch('https://uctdmbca31.execute-api.us-east-1.amazonaws.com/notifyuser', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: JSON.stringify({
                userId: partnerId,
                title: title,
                content: message
            })
        })
        .then(function(res) {
            console.log('Parceiro ' + partnerId + ' notificado.');
            return true;
        })
        .catch(function(err) {
            console.log(err.toString());
            return false;	
        })

    };


    // returns true or false
    notifyAll = async (title, message) => {
    
        return fetch('https://uctdmbca31.execute-api.us-east-1.amazonaws.com/notifyuser/', {
            method: 'POST',
            headers: {
                'Content-Type': 'application/json',
                'Accept': 'application/json'
            },
            body: {
                title: title,
                content: message
            }
        })
        .then(function(res) {
            console.log('Parceiros notificados.');
            return true;
        })
        .catch(function(err) {            
            console.log(err.toString());
            return false;	
        })

    };
    

	async getSubscriptionDb(userId) {
		const response = await axios.get('https://uctdmbca31.execute-api.us-east-1.amazonaws.com/LambdaSubGetItem/' + userId)        
        //console.log(response);
		if (response.status===200)
			return(response.data);
		else
			return;
	}

	async getSubscriptionSW() {
		if (!('serviceWorker' in navigator)) {
			return;
		}
		return navigator.serviceWorker.ready
		.then(function(swreg) {
			return swreg.pushManager.getSubscription();
		})
		.then(function(sub) {
    		//console.log(sub);
			return(sub);
		})
		.catch(function(err) {
			console.log(err.toString());
			return;
		});
	}

	async getSubscription(userId) {
		const subSW = await this.getSubscriptionSW();
		const subDB = await this.getSubscriptionDb(userId);
    	//console.log(subSW, subDB);
		if (subSW && subDB && subSW !== undefined && subSW.endpoint === subDB.endpoint ) {
            //console.log('tem subscription', subSW);
			return subSW;					
		} else {
    		//console.log('não tem subscription');
			//this.deleteSubscription(userId); //async method
			return;
		}
	}

	deleteSubscription(userId) {
		//from the Service Worker & Database
		//Service Worker
		navigator.serviceWorker.ready
		.then(reg => {
			return reg.pushManager.getSubscription();
		})
		.then(subscription => {
    		//console.log(subscription);
			return subscription.unsubscribe();
		})
		.then(successful => {
			// You've successfully unsubscribed
			console.log('Notificação desativada no cliente', successful);
		})
		.catch(err => {
			// Unsubscription failed
			console.log(err);
		})

		//Database
		fetch('https://uctdmbca31.execute-api.us-east-1.amazonaws.com/LambdaSubDelItem/' + userId, {
			method: 'DELETE',
			headers: {
				'Content-Type': 'application/json',
				'Accept': 'application/json'
			}
		})
		.then(function(res) {
    		console.log('Assinatura desativada no servidor');
			return res;
		})
		.catch(function(err) {
			console.log(err.toString());
			return;	
		})
				
		return true;
	}

    urlBase64ToUint8Array = (base64String) => {
        var padding = '='.repeat((4 - base64String.length % 4) % 4);
        var base64 = (base64String + padding)
        .replace(/-/g, '+')
        .replace(/_/g, '/');
    
        var rawData = window.atob(base64);
        var outputArray = new Uint8Array(rawData.length);
    
        for (var i = 0; i < rawData.length; ++i) {
        outputArray[i] = rawData.charCodeAt(i);
        }
        return outputArray;
    }

    configurePushSub = async (userId) => {
        if (!('serviceWorker' in navigator)) {
            return;
        }

        const results = await Promise.all([
            this.getSubscriptionSW(userId), 
            this.getSubscriptionDb(userId),
            navigator.serviceWorker.ready
        ]);
        
        //console.log(results);

        if (results[0] && results[1] && results[0].endpoint === results[1].endpoint) {
            //subscription existed in the Service Worker and DynamoDB
            this.displayNotification('As notificações já estavam ativas!');
            return;
        }
        
        if (results[0])  //has a subscription, but it aren't stored on the server for the given userId 
            await results[0].unsubscribe();

            
        //create new subscription in the browser
        var vapidPublicKey = 'BD2pAKtg-SnXATlul08gam2zcXOJeU41qT16FyQZ6SIvLDlUaYsJV4CXvvNxKBKqbGbwFVLYIVSDzwrOeLNfs0c';
        var convertedVapidPublicKey = this.urlBase64ToUint8Array(vapidPublicKey);
        //console.log(convertedVapidPublicKey);
        const newSub = await results[2].pushManager.subscribe({
                userVisibleOnly: true,
                applicationServerKey: convertedVapidPublicKey
            });			

        //storing the new subscription on the database
        if (newSub!==undefined) {
            var arg = JSON.parse(JSON.stringify(newSub));
            arg.id = userId;
            //console.log(arg);
            const res = await fetch('https://uctdmbca31.execute-api.us-east-1.amazonaws.com/LambdaSubPutItem', {
                method: 'POST',
                headers: {
                    'Content-Type': 'application/json',
                    'Accept': 'application/json'
                },
                body: JSON.stringify(arg)
            })
        
            if (res && res.ok) {			
                    this.displayNotification('Notificações ativadas com sucesso!');
                    return newSub;
                }
        }
    }

    displayNotification = (msg) => {
        if ('serviceWorker' in navigator) {
            let options = {
                body: msg,
                icon: '/icon_64x64.png',
                dir: 'ltr',
                lang: 'pt-BR',
                vibrate: [100,50, 200],
                badge: '/icon_128x128.png',
                tag: 'push',
                renotify: true
            }
            // ,
            // actions: [
            // 	{ action: 'confirm', title: 'Ok', icon: '' },
            // 	{ action: 'cancel', title: 'Cancel', icon: '' }
            // ]

            //console.log('showing notification pelo SW...');
            navigator.serviceWorker.ready
                .then(swreg => {
                    swreg.showNotification('Notificações Ativadas', options);
            })
        }
    }

}

