import React from 'react';
import { withRouter } from 'react-router-dom';
import Routes from './Routes';
import { Auth } from 'aws-amplify';
import * as serviceWorker from './serviceWorker';
import objSubscriptions from './logic/Subscriptions';
import PartnerService from './services/partnerService';
import { IPartnerDTO } from './models/partner';
import { connect } from 'react-redux';
import { RootState } from './app/store';
import { SET_PARTNER } from './app/reducers';
import Header from './components/Header';
import Snackbar from '@material-ui/core/Snackbar';
import Button from '@material-ui/core/Button';
import './App.css';

class App extends React.Component<any, any> {
	constructor(props: any) {
		super(props);

		this.state = {
			isAuthenticated: false,
			isAuthenticating: true,
			userFilter: 'todas',
			filter_label: "Filtrar",
			subscription: '',
			loading: true,
			snackbaropen: false,
			snackbarmsg: '',
			snackbaraction: '',
			newVersionAvailable: false,
			waitingWorker: {}
		};

		Auth.currentSession().then(user => this.userHasAuthenticated(true, user));
	}

	objSub = new objSubscriptions();

	snackbarClose = (event: any) => {		
		this.setState({snackbaropen: false});
	}

	userHasAuthenticated = async (authenticated: boolean, user: any = null) => {
		if (authenticated && this.props.user.sub) {
			console.log('Usuário já estava logado!!!')
			return;
		}
		if (authenticated) {			
			let sub : string;
			let email : string;
			if (user && user.attributes) {
				sub = await user.attributes.sub;
				email = await user.attributes.email;
			} else if (user && user.idToken && user.idToken.payload) {
				 sub = await user.idToken.payload.sub;
				 email = await user.idToken.payload.email;
			} else {
				alert('Usuário não identificado!');
				return;
			}
			
			let _partnerDTO: IPartnerDTO = {
				sub: sub,
				email: email,
				type: 'Ext',
				rewardPercentage: 0.00,
				clientDiscount: 0.00,
				clientBonus: '-'
			}
			let promisePartnerDTO = await PartnerService.GetOrCreatePartner(_partnerDTO);			
			if (promisePartnerDTO && promisePartnerDTO.id) {
				let snackbarconfig = await this.offerNotificationSubscription(promisePartnerDTO.id);
				this.setState({
					isAuthenticating: false, 
					isAuthenticated: true, 
					loading: false, 
					subscription: snackbarconfig.notificationSubscription,
					snackbaropen: snackbarconfig.open,
					snackbarmsg: snackbarconfig.msg,
					snackbaraction: snackbarconfig.action
				});
				
				this.props.dispatch(SET_PARTNER({
					id: promisePartnerDTO.id, 
					type: promisePartnerDTO.type, 
					sub: promisePartnerDTO.sub, 
					email: promisePartnerDTO.email, 
					rewardPercentage: promisePartnerDTO.rewardPercentage, 
					clientDiscount: promisePartnerDTO.clientDiscount, 
					clientBonus: promisePartnerDTO.clientBonus,
				}));
			} else {
				this.handleLogout(null);
			}
		} else {
			this.handleLogout(null);		
		}
	};


	async offerNotificationSubscription(partnerUserId: string) {		 
		const snackbarconfig = {
			notificationSubscription : await this.objSub.getSubscription(partnerUserId),
			open : this.state.snackbaropen,
			msg : this.state.snackbarmsg,
			action : this.state.snackbaraction
		}
		if (!snackbarconfig.open) {
			if (!snackbarconfig.notificationSubscription && !localStorage.getItem('askedForNotifications')) {
				snackbarconfig.open = true;
				snackbarconfig.msg = 'Aceita receber notificações* ?  (* apenas para Android) ?';
				snackbarconfig.action = this.subscribeAction(null);
				localStorage.setItem('askedForNotifications', 'true');				
			}
		}
		return snackbarconfig;
	}

	
	async componentDidMount() {
		let foundSession = false;

		// If you want your app to work offline and load faster, you can change
		// unregister() to register() below. Note this comes with some pitfalls.
		// Learn more about service workers: https://bit.ly/CRA-PWA
		serviceWorker.register({ onUpdate: this.onServiceWorkerUpdate });

		if (this.state.newVersionAvailable) {
			const newVersionAvailable = this.state.newVersionAvailable;
			if (newVersionAvailable) {
				this.setState({
					snackbaropen: true,
					snackbarmsg: 'Nova versão disponível!',
					snackbaraction: this.refreshAction(null) 				
				});
			}
		}

		let user:any;
		try {
			user = await Auth.currentSession();
			foundSession = true;
		} catch {}
		if (foundSession) {
			await this.userHasAuthenticated(true, user);

			//browser supports notification ?
			if ('Notification' in window) {
				const enableNotificationLink = document.querySelector<HTMLElement>('#enabnot');
				if (enableNotificationLink)
				 	enableNotificationLink.style.display = 'block';
			}
		} else {
			this.setState({
				isAuthenticating: false, 
				isAuthenticated: false, 
				loading: false, 
				subscription: null
			});

			this.props.dispatch(SET_PARTNER({
				id: '', 
				type: 'Ext', 
				sub: '', 
				email: '', 
				rewardPercentage: 0, 
				clientDiscount: 0, 
				clientBonus: ''
			}));			
		}
	}


	handleLogout = async (event:any) => {
		await Auth.signOut();
		//this.userHasAuthenticated(false);
		this.setState({
			isAuthenticating: false, 
			isAuthenticated: false, 
			loading: false, 
			// userId: null, 
			// userType: 'Ext', 
			// userSub: null, 
			// userEmail: null, 
			// rewardPercentage: null, 
			// clientDiscount: null, 
			// clientBonus: null,
			subscription: null
		});		
		this.props.dispatch(SET_PARTNER({
			id: '', 
			type: 'Ext', 
			sub: '', 
			email: '', 
			rewardPercentage: 0, 
			clientDiscount: 0, 
			clientBonus: ''
		}));

		this.props.history.push('/login');
	};

	handleDisableNotifications = async (event:any) => {
		if (this.objSub.deleteSubscription(this.props.user.id))
			this.setState({
				snackbaropen: true,
				snackbarmsg: 'Assinatura desativada!',
				snackbaraction: this.closeAction(null),
				subscription: null
			})
;
	}

	handleNotifications = (event:any) => {

		localStorage.setItem('askedForNotifications', 'true');
		
		if (Notification)
			Notification.requestPermission(async result => {
				if (result !== 'granted') {
					console.log('Notificações não autorizadas pelo usuário!');	
				} else {
					//create subscription on the server
					const el = document.getElementById('enabnot');
					let userId;
					if (el) {
						userId = el.getAttribute("data-userid");
					}
					const newsub = await this.objSub.configurePushSub(userId);
					this.setState({ subscription: newsub, snackbaropen: false, snackbarmsg: '' });			
				}
			});
		
	}


		

	deleteCache() {
		if ('caches' in window) {
		  	window.caches.delete('workbox-precache-v2-https://indica.minhacintamodeladora.com.br/');
			window.caches.delete('workbox-precache-v2-https://catalogo.minhacintamodeladora.com.br/');
			window.location.reload();
		}
	}

	onServiceWorkerUpdate = (registration:any) => {
		this.setState({
            waitingWorker: registration && registration.waiting,
            newVersionAvailable: true,
			snackbaropen: true,
			snackbarmsg: 'Nova versão disponível!',
			snackbaraction: this.refreshAction(null)
        });
    }
	
	updateServiceWorker = () => {
        const { waitingWorker } = this.state;
        waitingWorker && waitingWorker.postMessage({ type: 'SKIP_WAITING' });		
        this.setState({ newVersionAvailable: false, snackbaropen: false });
        window.location.reload();
    }

	closeAction = (key:any) => { //render the snackbar button
		return(
			<Button key="close" arial-label="Close" color="inherit" onClick={this.snackbarClose}>			
				x
			</Button>
		);
	}

	refreshAction = (key:any) => { //render the snackbar button
		return(
		<Button 
			key="Refresh"
			arial-label="Refresh"
			color="secondary"
			variant="contained"
			onClick={this.updateServiceWorker}
		>	
			{"Atualizar"}
		</Button>
		);
	};

	subscribeAction = (key:any) => { //render the snackbar button
		return(
		<Button 
			key="subscribe"
			arial-label="Subscribe"
			color="secondary"
			variant="contained"
			onClick={this.handleNotifications.bind(this)}
		>	
			{"Aceitar Notificações"}
		</Button>
		);
	};

	handleFilter = (ek:any, obj:any) => {
		this.setState({ userFilter: ek , filter_label : ek });
	};


	render() {   
		const userData = {
				// userId: this.state.userId,
				// userSub : this.state.userSub,
				// userEmail :this.state.userEmail,
				// userType: this.state.userType,	
				// rewardPercentage: this.state.rewardPercentage,
				// clientDiscount: this.state.clientDiscount,
				// clientBonus: this.state.clientBonus,
				userId: this.props.user.id,
				userSub : this.props.user.sub,
				userEmail :this.props.user.email,
				userType: this.props.user.type,	
				rewardPercentage: this.props.user.rewardPercentage,
				clientDiscount: this.props.user.clientDiscount,
				clientBonus: this.props.user.clientBonus,

			isAuthenticated: this.state.isAuthenticated,
			isAuthenticating: this.state.isAuthenticating,
			userHasAuthenticated: this.userHasAuthenticated,
			handleFilter: this.handleFilter,
			handleLogout: this.handleLogout,
			userFilter: this.state.userFilter || 'todas',
			filter_label: this.state.filter_label || 'Filtrar',
			subscription: this.state.subscription
		};
		return (
			<div>

				<Snackbar				
					anchorOrigin={{vertical: 'bottom', horizontal: 'center'}}
					open = {this.state.snackbaropen}
					autoHideDuration = {10000}
					message = {this.state.snackbarmsg}
					onClose = {this.snackbarClose}
					action = {this.state.snackbaraction}
				/>
				<Header userData={userData} />
				<Routes userData={userData} />
	  		</div>
		);
	}

}

const mapStateToProps = (state: RootState) => ({
	user: state.partner,
})

export default connect(mapStateToProps)(withRouter(App));