import React, { Component}  from "react";
import { Form, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import LoaderButton from './../components/LoaderButton';
import { API, graphqlOperation } from "aws-amplify";
import './NewPayment.css';
import Checkbox from './../components/Checkbox';
import * as queries from './../graphql/queries.ts';
import * as mutations from './../graphql/mutations.ts';
import { formatarData} from '../logic/myfunctions.js';
import NavLink from "react-bootstrap/NavLink";
import Subscriptions from '../logic/Subscriptions.js';
import axios from 'axios';

class NewPayment extends Component {
      parceiros = null;
      mensagem = '';
      mensagemSucesso = '';
      hoje = new Date().getFullYear() + '-' + ('0' + (new Date().getMonth() + 1)).slice(-2) + '-' + ('0' + new Date().getDate()).slice(-2);    
  

      constructor(props) {
        super(props);
        this.state = {
          autenticado: false,
          autorizado: false,
          PartnerSales: [],
          dtPayment: this.hoje,
          checkboxes: [""].reduce(
                (options, option) => ({
                  ...options,
                  [option]: false
                }),
                {}
              )
            };
      
      }
    
      async componentDidUpdate(prevProps, prevState,snapshot) {
        if (this.props.userId) {
          //access only for administrator
          if (this.props.userType==='IntAd') {
            if (!this.state.autorizado) {
              this.setState({autenticado:true, autorizado: true});
            }
            try {
                //atualiza o valor total das vendas selecionadas
                this.atualizaTotal();
                //a acesso a dados de outros parceiros será filtrado no carregamento do combo
                this.montaComboParceiro();
                // lista vendas do parceiro (apenas se foi alterado parceiro escolhido no combo)
                if (this.state.cmbParceiro!==prevState.cmbParceiro) {
                  if (this.state.cmbParceiro.length>10) {
                    //seleciona vendas parceiro
                    this.loadSalesData(this.state.cmbParceiro).then(mysales => {          
                      this.setState({
                          PartnerSales:mysales,
                          checkboxes:mysales.reduce(
                            (options, option) => ({
                              ...options,
                              [option.key]: false
                            }),
                            {})
                        });
      //                  console.log(this.state.checkboxes);
                    }).catch(err => console.log(err));
                }
              }          
            } catch (e) {
              console.log(e);
            }
          } else {
            if (!this.state.autenticado) {
              this.setState({autenticado: true, autorizado: false});
            }
          }
        }
      }

    /*
    componentDidMount() {
      console.log('component Did Mount');
      console.log('--------------------');
    }
    */



      selectAllCheckboxes = isSelected => {
        Object.keys(this.state.checkboxes).forEach(checkbox => {
          // BONUS: Can you explain why we pass updater function to setState instead of an object?
          this.setState(prevState => ({
            checkboxes: {
              ...prevState.checkboxes,
              [checkbox]: isSelected
            }
          }));
        });
      };
    
      selectAll = () => this.selectAllCheckboxes(true);
    
      deselectAll = () => this.selectAllCheckboxes(false);
    
      handleCheckboxChange = changeEvent => {
        const { name } = changeEvent.target;
        this.setState(prevState => ({
          checkboxes: {
            ...prevState.checkboxes,
            [name]: !prevState.checkboxes[name]
          }
        }));
        this.atualizaTotal();
      };

      atualizaTotal() {
        //recalcula valor a pagar
        var total = 0.00;
        if (document.getElementsByClassName("form-check-input")) {
            Array.from(document.getElementsByClassName("form-check-input")).forEach(
              function(element) {
                  if (element.checked)
                  {
        //                      console.log(document.getElementsByName("co"+element.name));
                      total += parseFloat(document.getElementsByName("co"+element.name)[0].value);
                  }
              }
            );
            if (document.getElementById("pTotal"))
              document.getElementById("pTotal").value = total.toFixed(2);
        }
      }

      changeHandler = event => {
      //        console.log(event.target.id);
        this.setState({ [event.target.id]: event.target.value });
      };
    
      handleFormSubmit = formSubmitEvent => {
        formSubmitEvent.preventDefault();
        var selectedOrders = [];
        Object.keys(this.state.checkboxes)
          .filter(checkbox => this.state.checkboxes[checkbox])          
          .forEach(checkbox => {
            console.log(checkbox, "is selected.");
            selectedOrders.push(checkbox);
          });

        this.insertPayment(
              document.getElementById("dtPayment").value, 
              document.getElementById("pTotal").value, 
              "N/D", 
              "N/D", 
              this.state.cmbParceiro, 
              selectedOrders 
              ); 

      };
    
      createCheckbox = option => (
        <Checkbox
          label={option.label}
          shortlabel={option.key}
          isSelected={this.state.checkboxes[option.key]}
          amount={option.array.Vlr}
          comission={option.array.Comissao}
          onCheckboxChange={this.handleCheckboxChange}
          key={option.key}
          />
      );
        
      createCheckboxes = () => this.state.PartnerSales.map(this.createCheckbox);
      

      montaComboParceiro = () => {   
        var x = document.getElementById("cmbParceiro");
        if (this.props.userType==='IntAd') {
          this.listPartners().then(parceiros => {
              this.parceiros = parceiros;
              if (document.getElementById("cmbParceiro").length<=1)
              {
                //ordenando parceiros
                this.parceiros.sort(function(a, b) {
                      if(a === null){
                        return 1;
                      }
                      else if(b === null){
                        return -1;
                      }
                      var nameA = (a.name || a.email).toUpperCase(); // ignore upper and lowercase
                      var nameB = (b.name || b.email).toUpperCase(); // ignore upper and lowercase
                      if (nameA < nameB) {
                        return -1;
                      }
                      if (nameA > nameB) {
                        return 1;
                      }
                      // names must be equal
                      return 0;
                    });
    
                for (var i=0;i<this.parceiros.length;i++) {
                  if (this.parceiros[i].type==='Ext') {
                    var option = document.createElement("option");
                    option.text = (this.parceiros[i].name || this.parceiros[i].email); // + ' (' + (this.parceiros[i].name || "sem nome") + ')';
                    option.value = this.parceiros[i].id;
                    if (option)
                      x.add(option);
                  }
                }
              }
          }).catch(err => {console.log(err);});
        }
      }
    
      async listPartners () {
        const myPartners = await API.graphql(graphqlOperation(queries.listPartners, {limit: 1000}));
        return(myPartners.data.listPartners.items);
      }
    
      async loadSalesData(usrID) {
          // var myData = await API.graphql({
          //   query: queries.listPartnerData,
          //   variables: { filter: { id: {eq : usrID} }, limit: 1000 },
          //   authMode: 'AWS_IAM'
          // }); 
          var myData;
          const response = await axios.get('https://1ir6efgdac.execute-api.us-east-1.amazonaws.com/' + usrID)        
          //console.log(response);
          if (response.status===200)
            myData = response.data;
          else
            return;
  
          myData = myData.filter(x=> !x.Paymentid && x.OrderAmount);
          myData.sort(function(a,b) {
            if (a.OrderDate>b.OrderDate)
              return 1
            else if (a.OrderDate<b.OrderDate)
              return -1
            else 
              return 0            
          })
          console.log(myData);
      
          var tempPartnerSales = [];

          for (var i=0;i<myData.length;i++) {            
                var itemLabel = 'Comissão: ' + parseFloat(myData[i].OrderRewardAmount).toFixed(2) +
                ' Data:' + formatarData(myData[i].OrderDate) + 
                ' Vlr.:' + myData[i].OrderAmount + 
                ' Doc.: ' + myData[i].OrderDocument + 
                ' Cliente: ' + myData[i].OrderClientName +
                ' Obs: ' + myData[i].OrderObs;

                var itemKey =  myData[i].OrderId;

                var itemArray = {'Comissao': parseFloat(myData[i].OrderRewardAmount).toFixed(2),
                'Data':formatarData(myData[i].OrderDate),
                'Vlr': parseFloat(myData[i].OrderAmount).toFixed(2),
                'Doc': myData[i].OrderDocument, 
                'Cliente': myData[i].OrderClientName,
                'Obs':myData[i].OrderObs}

                tempPartnerSales.push({"label":itemLabel,"key":itemKey, "array":itemArray});

              
            
          }
          
  /*            myData.data.listPartners.items[0].referrals.items.sort(function(a, b) {
                    var nameA = a.date; // ignore upper and lowercase
                    var nameB = b.date; // ignore upper and lowercase
                    if (nameA === null) {
                      return -1;
                    }
                    if (nameB === null) {
                      return 1;
                    }
                    if (nameA > nameB) {
                      return -1;
                    }
                    if (nameA < nameB) {
                      return 1;
                    }
                    // names must be equal
                    return 0;
                  });
    */  
//   console.log(myData.data.listPartners.items[0].referrals.items);
//           for (var i=0;i<myData.data.listPartners.items[0].referrals.items.length;i++) {
//             //console.log(myData.data.listPartners.items[0].referrals.items[i].order);
            
//             if (myData.data.listPartners.items[0].referrals.items[i].order!==null) {              
//               console.log(myData.data.listPartners.items[0].referrals.items[i].order);
// //              console.log(myData.data.listPartners.items[0].referrals.items[i].order.payment.items.length);
// //              console.log(myData.data.listPartners.items[0].referrals.items[i].order.payment.items[0].payment);
//               if ((myData.data.listPartners.items[0].referrals.items[i].order.rewardAmount>0) 
// //              && (myData.data.listPartners.items[0].referrals.items[i].order.payment.payment===null)) 
//               && (myData.data.listPartners.items[0].referrals.items[i].order.payment.items.length===0)) 
//               {

//                 console.log(myData.data.listPartners.items[0].referrals.items[i].order.payment.items[0]);
//                 //console.log(formatarData(myData.data.listPartners.items[0].referrals.items[i].order.date));
//                 var itemLabel = 'Comissão: ' + parseFloat(myData.data.listPartners.items[0].referrals.items[i].order.rewardAmount).toFixed(2) +
//                 ' Data:' + formatarData(myData.data.listPartners.items[0].referrals.items[i].order.date) + 
//                 ' Vlr.:' + myData.data.listPartners.items[0].referrals.items[i].order.amount + 
//                 ' Doc.: ' + myData.data.listPartners.items[0].referrals.items[i].order.document + 
//                 ' Cliente: ' + myData.data.listPartners.items[0].referrals.items[i].order.clientName +
//                 ' Obs: ' + myData.data.listPartners.items[0].referrals.items[i].order.obs;

//                 var itemKey =  myData.data.listPartners.items[0].referrals.items[i].order.id;

//                 var itemArray = {'Comissao': parseFloat(myData.data.listPartners.items[0].referrals.items[i].order.rewardAmount).toFixed(2),
//                 'Data':formatarData(myData.data.listPartners.items[0].referrals.items[i].order.date),
//                 'Vlr': parseFloat(myData.data.listPartners.items[0].referrals.items[i].order.amount).toFixed(2),
//                 'Doc':myData.data.listPartners.items[0].referrals.items[i].order.document, 
//                 'Cliente':myData.data.listPartners.items[0].referrals.items[i].order.clientName,
//                 'Obs':myData.data.listPartners.items[0].referrals.items[i].order.obs};

//                 tempPartnerSales.push({"label":itemLabel,"key":itemKey, "array":itemArray});

//               }
//             }
//           }
//              console.log(tempPartnerSales);  


          return(tempPartnerSales);
        
        }
    
      async insertPayment(pDtPayment, pPaymentAmount, pPaymentDescription, pPaymentObs, pPaymentParnerId, pPaymentOrdersId ) {

          try {
              //recuperando valores para inserir
              var newPayment = {
                "id": "",
                "date": pDtPayment,
                "paymentAmount": pPaymentAmount,
                "paymentDescription": pPaymentDescription,
                "paymentObs": pPaymentObs,
                "paymentPartnerId": pPaymentParnerId
              }
              var newPaymentOrders = [];

              //inserindo o pagamento
              var PaymentInserted = await API.graphql(graphqlOperation(mutations.createPayment, {input: newPayment}));    
              //...Retorno nova id do pagamento
              var PaymentIdInserted = PaymentInserted.data.createPayment.id;
              console.log('Pagamento inserido: ' + PaymentIdInserted);

              //inserindo as Vendas selecionadas para compor este pagamento
              for (var i=0;i<pPaymentOrdersId.length;i++) {
                newPaymentOrders.push({
                  "id": "",
                  "paymentOrdersPaymentId": PaymentIdInserted,
                  "paymentOrdersOrderId": pPaymentOrdersId[i]
                });
                var newOP = await API.graphql(graphqlOperation(mutations.createPaymentOrders, {input: newPaymentOrders[i]}));
                console.log('Venda associada: ' + newOP.data.createPaymentOrders.id);
              }              
              alert('Pagamento incluído com sucesso!');

              //enviando notificação
              const newNotification = new Subscriptions();
              newNotification.notifyUser(
              pPaymentParnerId,
              'Aviso de Crédito',
              'Data do crédito: ' + formatarData(pDtPayment) + ' Valor: R$ ' + pPaymentAmount
      );

           }
          catch (e) {
            //retornando mensagem de erro
            console.log("Oooops.  Ocorrência inserindo pagamento, verifique se o pagamento foi incluído.");
            console.log(e);
          }
        
      }
      
      renderDetalhe() {
        return(
          <div className="NewPayment">
            <h3>Novo Pagamento</h3>
            <div>
              <Form className=""
                    noValidate
                    id="frmPayment"
                    onSubmit={this.handleFormSubmit}
                    >
                  <FormGroup controlId="cmbParceiro">
                    <FormControl
                      required
                      pattern="[0-9a-f]{8}[-][0-9a-f]{4}[-][0-9a-f]{4}[-][0-9a-f]{4}[-][0-9a-f]{12}"
                      as="select"
                      size="lg"
                      placeholder="Parceiro"
                      value={this.state.cmbParceiro}
                      onChange={this.changeHandler}
                    >
                      <option value="0">Selecione o parceiro</option>
                    </FormControl>
                  </FormGroup>
                  <div>Vendas</div>
                  <div className="form-check">
                  {this.createCheckboxes()}
                  </div>
                  <div>

                    <LoaderButton 
                        type="button"
                        variant="btn btn-outline-secondary  btn-sm"
                        text="Marcar Todos"
                        onClick={this.selectAll}
                      >
                      </LoaderButton>
                    <LoaderButton
                        type="button"
                        variant="btn btn-outline-secondary  btn-sm"
                        text="Desmarcar Todos"
                        onClick={this.deselectAll}
                      >
                      </LoaderButton>

                  </div>
                  <div className="form-group mt-2">
                    <FormGroup controlId="pTotal">
                      <FormLabel>Total em comissões:</FormLabel>
                      <FormControl
                        size="lg"
                        type="text"
                        className="form-control"
                        placeholder="0.00"
                      />
                    </FormGroup>
                  </div>
                  <div>
                    <label>Data:</label>
                    <input type="date" id="dtPayment" value={this.state.dtPayment} onChange={this.changeHandler} className="form-group mt-2"></input>
                  </div>
                  <div className="NewPayment-Button"> 
                    <div>       
                    <NavLink 
                        className="btn btn-secondary btn-sm fifity"
                        href="/payment"
                      > Voltar
                    </NavLink>            
                    </div>
                    <div>
                    <LoaderButton
                      block
                      className="btn btn-secondary btn-sm fifity"
                      size="lg"
                      type="submit"
                      text="Incluir Pagamento"
                    >
                    </LoaderButton>
                    </div>
                  </div>
              </Form>
             </div>
          </div>
        );
      }


      render () {
          if (this.state.autorizado) {
            return(this.renderDetalhe());
          }
          else { //não autorizado
            if (!this.state.autenticado)
              this.mensagem = 'Verificando credenciais do usuário, aguarde...';
            else
              this.mensagem = "Acesso negado para usuário.";
            return(<div className="newPayment"><p>{this.mensagem}</p></div>);
          }
      }
    
    }
    export default NewPayment;
    