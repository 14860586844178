import React, { Component } from 'react';
import { Button } from 'react-bootstrap';
import './Config.css';
import ProductService from './../services/productService';

export default class Config extends Component {
	constructor(props: any) {
		super(props);


		this.state = {			
		};
	}

	render() {
		return(
			<div className="Config">
				<div className="mt-2 ml-2">
					<Button onClick={ProductService.insertNewProductsIntoDB}>Insert New Products Into DB
                    </Button>
				</div>
			</div>
		);
	}
}
