import React, {useState} from "react";
import awsmobile from '../aws-exports';
import { Form, FormGroup, FormControl } from 'react-bootstrap';
import { API, graphqlOperation } from "aws-amplify";
import { Auth } from 'aws-amplify';
import * as queries from './../graphql/queries.ts';
import Table from '../components/Table';
import axios from 'axios';
import {formatarDataAlternativa} from '../logic/myfunctions.js';
import 'bootstrap/dist/css/bootstrap.min.css';
import BTable from 'react-bootstrap/Table';
//import { TemporaryCredentials } from "aws-sdk";


 function RelSale(props) {   
  // data state to store the TV Maze API data. Its initial value is an empty array
  const [data, setData] = useState([]);
  const [referrals, setReferrals] = useState([]);
  const [isComission, setIsComission] = useState([]);
  const [totalheader, setTotalHeader] = useState([]);
  let nextPaymentDate = new Date();
  const [autenticado, setAutenticado] = useState(false);
  const [autorizado, setAutorizado] = useState(false);
  const [cmbParceiro, setCmbParceiro] = useState('');

  const columns = React.useMemo(
     () => [
       {
         Header: 'Data',
         accessor: 'col1', // accessor is the "key" in the data
       },
       {
         Header: 'Doc',
         accessor: 'col2',
       },
       {
        Header: 'Cliente',
        accessor: 'col3',
      },
      {
        Header: 'Comissão',
        accessor: 'col4',
      },
      {
        Header: 'Dt. Pagto.',
        accessor: 'col5',
      },
     ],
     []
   ) 

   const columns0 = React.useMemo(
    () => [
      {
        Header: 'Data',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Doc',
        accessor: 'col2',
      },
      {
       Header: 'Cliente',
       accessor: 'col3',
     },
    ],
    []
  ) 

  const colPending = React.useMemo(
    () => [
      {
        Header: 'Data',
        accessor: 'col1', // accessor is the "key" in the data
      },
      {
        Header: 'Celular',
        accessor: 'col2',
      },
      {
       Header: 'Desc.%',
       accessor: 'col3',
     },
     {
      Header: 'url',
      accessor: 'col4',
    },

    ],
    []
  ) 
  
  let parceiros = null;
  let prazoValidade = 90; //em dias
       
    if (props.userId) {
        if (props.userType==='IntOp' || props.userType==='IntAd' || props.userType==='Ext') {
            if (!autorizado) {
                setAutenticado(true);
                setAutorizado(true);
                API.configure(awsmobile);
                Auth.configure(awsmobile);
                Auth.currentCredentials()
                .then(anonymousUser => {
                    try {
                        //a acesso a dados de outros parceiros será filtrado no carregamento do combo
                        montaComboParceiro();
                        // monta relatório de vendas do parceiro
                        if (document.getElementById("cmbParceiro")) {
                          if (document.getElementById("cmbParceiro").value.length>10) {
                            var partnerSelected = document.getElementById("cmbParceiro").value;
                            loadRelData(partnerSelected).then(tmpData => {                             
                              setData(tmpData);  
                            })
                          }
                        }
                    } catch (e) {
                      console.log(e);
                    }          
                    console.log('')
                })
                .catch(e => console.log('error: ', e));
            } else {
                if (!autenticado) {
                  setAutenticado(true);
                  setAutorizado(false);
                }
            }

          }
     }

 
   const montaComboParceiro = function()  {
     var x = document.getElementById("cmbParceiro");
     if (props.userType==='Ext') {
       if (document.getElementById("cmbParceiro").length<=1)
       {
         var option = document.createElement("option");
         option.text = props.userEmail;
         option.value = props.userId;
         x.add(option);
       }
     }
     else {
       listPartners().then(partners => {
           parceiros = partners;
           if (document.getElementById("cmbParceiro").length<=1)
           {
             //ordenando parceiros
             parceiros.sort(function(a, b) {
                   if(a === null){
                     return 1;
                   }
                   else if(b === null){
                     return -1;
                   }
                   var nameA = (a.name || a.email).toUpperCase(); // ignore upper and lowercase
                   var nameB = (b.name || b.email).toUpperCase(); // ignore upper and lowercase
                   if (nameA < nameB) {
                     return -1;
                   }
                   if (nameA > nameB) {
                     return 1;
                   }
                   // names must be equal
                   return 0;
                 });
 
             for (var i=0;i<parceiros.length;i++) {
               if (parceiros[i].type==='Ext') {
                 var option = document.createElement("option");
                 option.text = (parceiros[i].name || parceiros[i].email); // + ' (' + (parceiros[i].name || "sem nome") + ')';
                 option.value = parceiros[i].id;
                 x.add(option);
               }
             }
           }
       }).catch(err => {console.log(err);});
     }
   }
 
   const changeHandler = event => {       
      setCmbParceiro(event.target.value);
      loadRelData(event.target.value).then(tmpData => 
        {
          if (tmpData && tmpData[0] && tmpData[0].col4>0)
            setIsComission(true);
          else
            setIsComission(false);

          setData(tmpData);
        }
        ).catch(err => console.log(err));      

      pendingReferral(event.target.value);
   };

   const pendingReferral = function(userId) {
        API.graphql({
          query: queries.listPartnerData,
          variables: { filter: { id: {eq : userId} } },
          authMode: 'AWS_IAM'
        }).then(myData => {
          var openReferrals =[];
          for (var i=0;i<myData.data.listPartners.items[0].referrals.items.length;i++) {            
            if (myData.data.listPartners.items[0].referrals.items[i] && myData.data.listPartners.items[0].referrals.items[i].order===null && myData.data.listPartners.items[0].referrals.items[i].clientPhone!=='N/D') {              
              var dtIndicacao = new Date (myData.data.listPartners.items[0].referrals.items[i].date);
              var dtValidade = addDays(dtIndicacao, prazoValidade);
              var dtHoje = new Date();
              var vencido = dtValidade < dtHoje;
              if (!vencido) {
                  var referral = {
                        col4: 'https://desconto.minhacintamodeladora.com.br/?referralid=' + myData.data.listPartners.items[0].referrals.items[i].id, 
                        col1: formatarDataAlternativa(dtIndicacao),
                        col2: myData.data.listPartners.items[0].referrals.items[i].clientPhone,
                        col3: 100*parseFloat(myData.data.listPartners.items[0].referrals.items[i].clientDiscount).toFixed(2),
                        sort: dtIndicacao
                  };
                  openReferrals.push(referral);
              }
            }            
          }
          openReferrals.sort(function(a,b) {             
              if (a.sort>b.sort)
                return -1
              else if (a.sort<b.sort)
                return 1
              else 
                return 0            
            })    
          openReferrals=openReferrals.map(o=> {return {col1: o.col1, col2: o.col2, col3: o.col3, col4:o.col4}});
          setReferrals(openReferrals);
        });
   }

   const loadRelData = async function(partnerId) {
        if (!partnerId || partnerId.length<36) {
          return([{
            col1: '',
            col2: '',
            col3: '',
            col4: '',
            col5: ''
        }])
        }
        var myData;
        const response = await axios.get('https://1ir6efgdac.execute-api.us-east-1.amazonaws.com/' + partnerId)        
        if (response.status===200)
          myData = response.data;
        else
          return;

        myData = myData.filter(x=> x.OrderAmount);
        myData.sort(function(a,b) {
          if (a.OrderDate>b.OrderDate)
            return -1
          else if (a.OrderDate<b.OrderDate)
            return 1
          else 
            return 0            
        })
    
        let currentDate = new Date();                
        let nextCutDate = new Date();                
        
        if (currentDate.getDate()>20) {
          //next month
          
            if (currentDate.getMonth() === 11) {
              nextPaymentDate = new Date(currentDate.getFullYear()+1, 0, 20);
              nextCutDate = (new Date(currentDate.getFullYear()+1, 0, 1));                   
            } else {
              nextPaymentDate = new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 20);                    
              nextCutDate = (new Date(currentDate.getFullYear(), currentDate.getMonth()+1, 1)); 
            }
        } else {
            //this month
            nextPaymentDate = new Date(currentDate.getFullYear(),currentDate.getMonth(),20);
            nextCutDate = ( new Date(currentDate.getFullYear(),currentDate.getMonth(),1));
        }        
        
        let nextPayment = 0;
        setTotalHeader("Próx.Crédito: R$ " + 
        (parseFloat(nextPayment)>=100  
        ? parseFloat(nextPayment).toFixed(2) + " (" + formatarDataAlternativa(nextPaymentDate) + ")" 
        : "0,00 (" + formatarDataAlternativa(nextPaymentDate) + ")"));   


        return(myData.map(function(el) {                
                let orderDate = new Date(el['OrderDate']);

                if (!el['PaymentDate'] && orderDate < nextCutDate ) {
                  nextPayment = parseFloat(nextPayment) + parseFloat(el['OrderRewardAmount']);
                  setTotalHeader("Próx.Crédito: R$ " + 
                    (parseFloat(nextPayment)>=100  
                    ? parseFloat(nextPayment).toFixed(2) + " (" + formatarDataAlternativa(nextPaymentDate) + ")" 
                    : "0,00 (" + formatarDataAlternativa(nextPaymentDate) + ")"));   
                } 

                return({
                    col1: formatarDataAlternativa(el['OrderDate']),
                    col2: el['OrderDocument'],
                    col3: el['OrderClientName'],
                    col4: parseFloat(el['OrderRewardAmount']).toFixed(2),
                    col5: !el['PaymentDate'] ? 'Pendente' : formatarDataAlternativa(el['PaymentDate']),
                })
            }));
       

   }


   const listPartners = async function() {
     const myPartners = await API.graphql(graphqlOperation(queries.listPartners, {limit: 1000}));
     return(myPartners.data.listPartners.items);
   }
 
   const addDays = function(date, days) {
     const copy = new Date(date);
     copy.setDate(copy.getDate() + days);
     return copy;
   }

   return (
    
    <div className="mySaleContainer">
    <h2>Histórico de Influência</h2>
    <div className="relSaleFormulario">
      <Form className="needs-validation"
            noValidate
            id="frmRelSale"
            >
        <FormGroup controlId="cmbParceiro">
          <FormControl
             required
             pattern="[0-9a-f]{8}[-][0-9a-f]{4}[-][0-9a-f]{4}[-][0-9a-f]{4}[-][0-9a-f]{12}"
             as="select"
             size="lg"
             placeholder="Parceiro"
             value={cmbParceiro}
             onChange={changeHandler}
          >
            <option value="0">Selecione o parceiro</option>
          </FormControl>
        </FormGroup>
      </Form>
    </div>
    <div id="mainPlaceHolder" className="x-3">
    {(cmbParceiro && isComission) && <BTable striped bordered hover size="sm" >
        <Table columns={columns} data={data} filter="col3" filterplaceholder="Buscar Cliente" totalheader={totalheader}/>
      </BTable>}
    {(cmbParceiro && !isComission) && <BTable striped bordered hover size="sm" >
        <Table columns={columns0} data={data} filter="col3" filterplaceholder="Buscar Cliente"/>
      </BTable>}
    </div>
    <div>
      
      {(cmbParceiro && referrals) && <span className="m-2" >Indicações em aberto (últimos {prazoValidade} dias)
      <BTable striped bordered hover size="sm" >
        <Table columns={colPending} data={referrals} filter="col2" filterplaceholder="Buscar Celular" />
      </BTable>
      </span>}
    </div>
    </div>


  )

 }

 export default RelSale;
