import React, { useEffect, useState } from 'react';
import { Alert, Container, Row, Col, Form, FormGroup, FormControl, FormLabel } from 'react-bootstrap';
import awsmobile from '../aws-exports';
import * as queries from './../graphql/queries.ts';
import * as mutations from './../graphql/mutations.ts';
import { Auth } from 'aws-amplify';
import { API, graphqlOperation } from "aws-amplify";
import LoaderButton from './LoaderButton';
import './InputPhone.css';
import PhoneInput from 'react-phone-number-input';

const SpinnerPage = () => {
    return (
      <>
        <div className="spinner-border" role="status">
          <span className="sr-only">Loading...</span>
        </div>
      </>
    );
  }


const  PartnerDetail = (props) => {
    const [isLoading, setIsLoading] = useState(true);
    const [showAlert, setShowAlert] = useState(false);
    const [showStatus, setShowStatus] = useState('success');
    const [msg, setMsg] = useState('');
    const [autenticado, setAutenticado] = useState(props.userData.isAuthenticated);
    const [autorizado, setAutorizado] = useState(false);
    
    //    console.log(props);
    const [state, setState] = useState({'id': props.data.id, 'email':'', 'name': '', 'clientDiscount':'', 'rewardPercentage':'', 'birthday':'', 'phone':'', 'gender':0 });

    useEffect(() => { 
/*        async function popData () {
            const myPartners = await API.graphql(graphqlOperation(queries.listPartners, {limit: 1000}));
            console.log(myPartners);
            return(myPartners.data.listPartners.items);
        }
*/
        console.log(state.id, autenticado, autorizado, props.userData.userId, props.userData.userType);
        if (props.userData.userId) {
            if (props.userData.userType === 'IntAd') {
                if (!autorizado) {
                    setAutorizado(true);
                    setAutenticado(true);
                }

                API.configure(awsmobile);
                Auth.configure(awsmobile);
                Auth.currentCredentials()
                .then(anonymousUser => console.log('data: ', anonymousUser))
                .catch(e => console.log('error: ', e));
                
                API.graphql({
                query: queries.getPartner,
                variables: { id: state.id },
                authMode: 'AWS_IAM'
                }).then(myPartner => {
        //            console.log(myPartner);
                    setState({'id': myPartner.data.getPartner.id, 
                        'email': myPartner.data.getPartner.email, 
                        'name': (myPartner.data.getPartner.name || ''), 
                        'clientDiscount': Math.floor(myPartner.data.getPartner.clientDiscount*100), 
                        'rewardPercentage': Math.floor(myPartner.data.getPartner.rewardPercentage*100), 
                        'birthday': (myPartner.data.getPartner.birthday || ''), 
                        'phone': (myPartner.data.getPartner.phone || ''), 
                        'gender': (myPartner.data.getPartner.gender || 0)});   
                    setIsLoading(false);
                }).catch(err => {console.log(err);});

            }
            else
            {
                if (props.isAuthenticated) {
                    setAutenticado(true);
                    setAutorizado(false);
                }          
            }
        }

    },  [state.id, autenticado, autorizado, props.userData.userId, props.userData.userType, props.isAuthenticated] );

    const changeHandler = (event) => {
        if (event===undefined || event.target===undefined)
            setState({...state, 'phone': event });
       else
            if (event.target.id === 'clientDiscount' || event.target.id === 'rewardPercentage') {
                let {value, max, min} = event.target;
                value = Math.min(Math.max(Number(value), Number(min)), Number(max));
                setState({...state,  [event.target.id]: value });

            } 
            else
               setState({...state,  [event.target.id]: event.target.value });

    }

    const submitHandler = event => {
        event.preventDefault();
        event.target.className += " was-validated";
        setShowAlert(true);
        if (state.clientDiscount+state.rewardPercentage>25) {
            setShowStatus('danger');
            setMsg('A soma do Desconto do Cliente e da Comissão deve ser inferior a 25%.  Gentileza ajustar valores.');
            return(null);
        }
        if (event.target.checkValidity()) {            

            updatePartner(state)
            .then(() => {
                setMsg('Dados atualizados com sucesso!');
                setShowStatus('success');
            })
            .catch(err => console.log(err));

        }
      }
    
      const updatePartner = async (dados) => {
            const updatePartnerInput = {id: dados.id,
                                        name: dados.name,
                                        phone: (dados.phone === '' ? null : dados.phone),
                                        birthday: (dados.birthday === '' ? null : dados.birthday),
                                        gender: (dados.gender === '' ? null : dados.gender),
                                        rewardPercentage: (dados.rewardPercentage/100).toFixed(2),
                                        clientDiscount: (dados.clientDiscount/100).toFixed(2)
                                    };
            API.graphql(graphqlOperation(mutations.updatePartner, {input: updatePartnerInput}))
            .then(
                console.log('Dados atualizados'))
            .catch(e => console.log('Erro:' + e));

      } 

      /*    const BlurHandler = event => {
        if (document.getElementById(event.target.id)==="0") {
          document.getElementById(event.target.id).setCustomValidity(event.target.id + ' inválido!');
        }
        else {
          document.getElementById(event.target.id).setCustomValidity('');
        }
      }
  */  
    return(
        (autorizado ? 

        <Container fluid>
            <Row>
                <Col className = 'p-4 bg-warning'>
                    <Form className="needs-validation"
                        onSubmit={submitHandler}
                        noValidate
                        id="frmPartner"
                        autoComplete="off"
                    >
                        <FormGroup controlId="id">
                            <FormLabel>Id:</FormLabel>
                            <FormControl 
                            disabled
                            type="text"
                            size="lg"
                            value={state.id}
                            className="form-control"
                            />
                        </FormGroup>

                        {(isLoading ? 
                            <SpinnerPage />
                        :
                            <div>                        
                            <FormGroup controlId="email">
                                <FormLabel>Email:</FormLabel>
                                <FormControl
                                disabled
                                type="text"
                                size="lg"
                                className="form-control"
                                value={state.email}
                                />
                                <FormControl.Feedback type="invalid">Nome do parceiro obrigatório</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup controlId="name">
                                <FormLabel>Nome do Parceiro:</FormLabel>
                                <FormControl
                                required
                                autoComplete="off"
                                type="text"
                                size="lg"
                                placeholder="<nome parceiro> (<médico ou clínica>)"
                                className="form-control"
                                value={state.name}
                                onChange={changeHandler}
                                />
                                <FormControl.Feedback type="invalid">Nome do parceiro obrigatório</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup controlId="clientDiscount">
                                <FormLabel>Desconto do cliente (%):</FormLabel>
                                <FormControl
                                required
                                size="lg"
                                type="number"
                                className="form-control"
                                value={state.clientDiscount}
                                placeholder="10"
                                min='0'
                                max='15'
                                onChange={changeHandler}
                                />
                                <FormControl.Feedback type="invalid">Desconto do cliente obrigatório</FormControl.Feedback>
                            </FormGroup>
                            <FormGroup controlId="rewardPercentage">
                                <FormLabel>Comissão (%):</FormLabel>
                                <FormControl
                                required
                                size="lg"
                                type="number"
                                className="form-control"
                                value={state.rewardPercentage}
                                onChange={changeHandler}
                                placeholder="10"
                                min='0'
                                max='30'
                                />
                                <FormControl.Feedback type="invalid">Comissão obrigatória, pode ser 0%</FormControl.Feedback>
                            </FormGroup>

                            <FormGroup controlId="birthday">
                                <FormLabel>Aniversario</FormLabel>
                                <FormControl
                                    type="date"
                                    size="lg"
                                    value={state.birthday}
                                    onChange={changeHandler}
                                >
                                </FormControl>
                                <FormControl.Feedback type="invalid">Data de aniversário</FormControl.Feedback>
                            </FormGroup>
                            
                            <FormGroup controlId="gender">
                                <FormLabel>Gênero:</FormLabel>
                                <FormControl
                                    as="select"
                                    size="lg"
                                    placeholder="Escolha o gênero"
                                    value={state.gender}
                                    onChange={changeHandler}>
                                        <option value="0">Escolha</option>
                                        <option value="F">Feminino</option>
                                        <option value="M">Masculino</option>
                                        <option value="O">Outros</option>
                                </FormControl>
                            </FormGroup>

                            <FormGroup controlId="phone">
                                <FormLabel>Telefone:</FormLabel>
                                <PhoneInput
                                autoComplete="off"
                                defaultCountry="BR"
                                placeholder="Celular do Cliente com DDD"
                                value={state.phone}
                                onChange={changeHandler}
                                limitMaxLength = {true}

                                />                                
                            </FormGroup>

                            <Alert show={showAlert} variant={showStatus}>
                                <p>
                                {msg}
                                </p>
                            </Alert>

                            <LoaderButton
                                block
                                variant="secondary"
                                size="lg"
                                type="submit"
                                text="Atualizar Dados"
                            />
                            </div>
                            )}
                    </Form>   
             
                </Col>
            </Row>
        </Container>
        :
        (!autenticado ?
            <div className="mySaleContainer"><p>Verificando credenciais do usuário, aguarde</p></div>
        :
            <div className="mySaleContainer"><p>Acesso negado para usuário.</p></div>
        )
    )

    );
}

export default PartnerDetail; 