import React from 'react';
import ReactDOM from 'react-dom';
import Amplify from 'aws-amplify';
import { BrowserRouter as Router } from 'react-router-dom';
import App from './App.tsx';
import config from './aws-exports';
import './index.css';
import store from './app/store'
import { Provider } from 'react-redux'

Amplify.configure(config);

ReactDOM.render(
  <Router>
    <Provider store={store}>
      <App />
    </Provider>
  </Router>,
	document.getElementById('root')
);

