import React, { useState }  from "react";
import { FormText, FormGroup, FormControl, FormLabel, Alert, Button } from 'react-bootstrap';
import LoaderButton from '../components/LoaderButton';
import { Auth } from 'aws-amplify';
import './../containers/Signup.css';

export function ConfirmationForm(props) {

    const [isLoading, setIsLoading] = useState(false);
    const [confirmationCode, setConfirmationCode] = useState('');    
    const [msg,setMsg] = useState('');
    const [isSuccess,setIsSuccess] = useState(false);

    var resendConfirmationCode = async () => {
        try {
            //await Auth.resendSignUp(props.newUser.userSub);   
            await Auth.resendSignUp(props.email);            
            setMsg('Código enviado com sucesso');
        } catch (err) {
            setMsg('Erro enviando código: ' + err.message);
        }
    }

    var handleConfirmationSubmit = async event => {
		event.preventDefault();
        setMsg('');
        setIsLoading(true);

		try {
			var data = await Auth.confirmSignUp(props.email, confirmationCode);
            if (data) {
                try {
                    props.callback();
                } catch{}
                setIsSuccess(true);
            }
            else {
                setMsg('Algo inesperado ocorreu.  Tente efetuar o login, e se não conseguir entre em contato com seu representante.');
                return;
            } 
		} catch (e) {
			setMsg('Erro: ' + e.message);
			
		} finally {
            setIsLoading(false);
        }
	};    

    var validateConfirmationForm = () => {
		return confirmationCode.length > 0;
	}

    return (
        (isSuccess?
            <Alert show={true} variant="success">
            <Alert.Heading>Email confirmado com sucesso!</Alert.Heading>
            <p>
              Obrigado por ter escolhido a Minha Cinta Modeladora - Distribuidora Oficial da Yoga Confecções.  Estamos felizes em poder atendê-los.
            </p>
            <hr />
            <div className="d-flex justify-content-end">
              
                <Button href="login" variant="outline-success">
                    Clique Aqui Para Efetuar o Login!
                </Button>
              
            </div>
          </Alert>
        :
            <form onSubmit={handleConfirmationSubmit}>                
                <FormGroup controlId="confirmationCode">
                    <FormLabel>Código de confirmação</FormLabel>
                    <FormControl autoFocus type="tel" value={confirmationCode} onChange={e => setConfirmationCode(e.target.value)} />
                    <FormText>Por favor, verifique o código no seu email ({props.email}).</FormText>                                
                </FormGroup>
                <LoaderButton
                    block
                    variant="secondary"
                    size="lg"
                    disabled={!validateConfirmationForm()}
                    type="submit"
                    isLoading={isLoading}
                    text="Validar"
                    loadingText="Validando…"
                />
                <FormText>{msg}</FormText>                
                <Button variant="outline-warning" size="sm" onClick={resendConfirmationCode}>Não recebeu?  Clique aqui para enviar novamente</Button>
            </form>
        )
    );
}
export default ConfirmationForm;
