export interface IProduct {
    id: string;             //id
    brand: string;
    compression: string;    //tag
    description: string;    //description
    imgurl0: string;        //path
    imgurl1: string;
    imgurl2: string;    
    material: string;
    reference: string;      //ref
}

export interface IProductDTO {
    id: string;
    tag: string;
    description: string;
    path: string;
    linha: string;  //TODO: change to line
    logo: string;
    ref: string;
}

export interface INewProductDTO {    
    tag: string;
    description: string;
    path: string;
    linha: string;  //TODO: change to line
    logo: string;
    ref: string;
}

export function mapIProductToIProductDTO(productSource: IProduct): IProductDTO {
    var productDTO: IProductDTO = {
        id : productSource.id,
        tag : productSource.compression,
        description: productSource.description,
        path: productSource.imgurl0,
        linha: '',
        logo: '',
        ref: productSource.reference
    };
    
    return productDTO;
}

export function mapIProductDTOToIProduct(productSource: IProductDTO): IProduct {
    var product: IProduct = {
        id : productSource.id ? productSource.id : '',
        compression : productSource.tag,
        description: productSource.description,
        imgurl0: productSource.path,
        reference: productSource.ref,
        brand: '',
        imgurl1: '',
        imgurl2: '',
        material: ''
    };   
    return product;
}

