import React, { Component } from 'react';
import { FormGroup, FormControl } from 'react-bootstrap';
import LoaderButton from '../components/LoaderButton';
import { Auth } from 'aws-amplify';
import './Signup.css';
import SignupConfirmation from './../components/SignupConfirmation';

export default class Signup extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			email: '',
			password: '',
			confirmationCode: '',
			newUser: null
		};
	}

	validateForm() {
		return (
			this.state.email.length > 0 &&
			this.state.password.length > 0
		);
	}


	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	handleSubmit = async event => {
		event.preventDefault();

		this.setState({ isLoading: true });

		try {
			const newUser = await Auth.signUp({
				username: this.state.email,
				password: this.state.password
			});
			this.setState({
				newUser
			});
		} catch (e) {
			alert(e.message);
		}

		this.setState({ isLoading: false });
	};


	renderConfirmationForm() {
		return (
			<SignupConfirmation email={this.state.email} setUserConfirmed={()=>{}}></SignupConfirmation>
		);
	}

	renderForm() {
		return (
			<form onSubmit={this.handleSubmit}>
				<h2>Cadastre-se</h2>
				<p>Etapa 1: informe seu email, crie uma nova senha de acesso e clique em Salvar.</p>
				<p>Etapa 2: você receberá no email informado um código para confirmação na sequência.</p>
				<FormGroup controlId="email">
					<FormControl autoFocus type="email" value={this.state.email} onChange={this.handleChange} placeholder="Seu Email Aqui" />
				</FormGroup>
				<FormGroup controlId="password">
					<FormControl value={this.state.password} onChange={this.handleChange} type="password" placeholder="Sua Senha Aqui"/>
				</FormGroup>
				<LoaderButton
					block
					variant="secondary"
					size="lg"
					disabled={!this.validateForm()}
					type="submit"
					isLoading={this.state.isLoading}
					text="Salvar"
					loadingText="Processando…"
				/>
			</form>
		);
	}

	render() {
		return (
			<div className="Signup">{this.state.newUser === null ? this.renderForm() : this.renderConfirmationForm()}</div>
		);
	}
}
