import React from 'react';
import './MyReferralDiscount.css';
import { Card } from 'react-bootstrap';
import QRCode from 'qrcode';

class MyReferralDiscount extends React.Component {
  myPercent = 5;
  constructor(props) {
		super(props);
    this.state = {referralId: props.id, desconto: (100*props.myReferral.clientDiscount).toFixed(0), order: null};
	}


  formatDate(dt) {
    return(dt.slice(8,10)+'/'+dt.slice(5,7)+'/'+dt.slice(0,4)+' '+(dt.slice(11,13)-3)+':'+dt.slice(14,16));
  }

  async componentDidMount() {
    var canvas1 = document.getElementById('canvas1');
    if (this.props.myReferral)
    {
      this.props.setRewardPercentage(this.props.myReferral.rewardPercentage);
      if (!this.props.myReferral.order && !this.props.vencido)
      {

          QRCode.toCanvas(canvas1, this.props.myReferral.id, function (error) {});
          if (this.state.desconto === '')
          {
            this.setState({desconto : (100*this.props.myReferral.clientDiscount).toFixed(0) });
          }
      }
    }

  }

  async componentDidUpdate ()  {
    var canvas1 = document.getElementById('canvas1');
    if (this.props.myReferral)
    {
      if (!this.props.myReferral.order && !this.props.vencido)
      {
          QRCode.toCanvas(canvas1, this.props.myReferral.id, function (error) {});
          if (this.state.desconto === '')
          {
            this.setState({desconto : (100*this.props.myReferral.clientDiscount).toFixed(0) });
            this.props.setRewardPercentage(this.props.myReferral.rewardPercentage);
          }
      }
    }
  }



  render() {
    return(
      (this.props.myReferral.order===null && !this.props.vencido ? 
      <Card style={{ width: 'auto' }}>
        <Card.Body>
          <Card.Text className="text-center">
            <canvas id="canvas1" className="myReferralDiscount__qrcode"></canvas>
          </Card.Text>
        </Card.Body>
          <Card.Footer name="desconto" variant="success" className="text-center">
                  <h5>Vale {this.state.desconto} % de desconto!</h5>
          </Card.Footer>
      </Card>
      :
      <Card style={{ width: 'auto' }}>
        <Card.Body>
          <Card.Text className="text-center">
            <span id="qrcodeutilizado" className="myReferralDiscount__label--qr"></span>
          </Card.Text>
        </Card.Body>
          <Card.Footer name="desconto" variant="success" className="text-center">
 {         (this.props.myReferral.order!==null ? 
                  <h5>Desconto utilizado em {this.formatDate(this.props.myReferral.order.date)}</h5>
          :
                  <h5>Desconto vencido!</h5>
          )}
          </Card.Footer>
      </Card>

      )
    );
  }
}
export default MyReferralDiscount;
