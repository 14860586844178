import React, { Component}  from "react";
import LoaderButton from './../components/LoaderButton';
import SelectedProducts from './SelectedProducts';
import { Form, FormGroup, FormControl, FormLabel, FormCheck } from 'react-bootstrap';
import { API, graphqlOperation } from "aws-amplify";
import * as mutations from './../graphql/mutations.ts';
import './InputPhone.css';
import PhoneInput, {  isValidPhoneNumber } from 'react-phone-number-input';

class MyReferral extends Component {

  constructor(props) {
    super(props);

    this.state = {
      linha: '',
      nroWhatsapp: '',
      indicacaoObs: '',
      nmCliente: '',
      envio: '',
      url_cliente_referral: '', 
      ClientWhatsapp: '',
      estado: 1
    }
  }

  submitHandler = event => {
    event.preventDefault();
    if (!isValidPhoneNumber(this.state.nroWhatsapp)) {
      alert('Telefone Inválido');
      return;
    }

    event.target.className += " was-validated";
    if (event.target.checkValidity()) {
       //Salva indicação
       this.saveReferral(document.getElementById("nmCliente").value,this.state.nroWhatsapp,this.props.produtos, document.getElementById("indicacaoObs").value, document.getElementById("linhasoft").checked ? "soft" : "premium").then(msg => {
      }).catch(err => {
         alert(err);
       });
       document.getElementById("mcm-confirmacao").style.display = "none";
     }
  };

  
  zeraForm(msg) {
      this.props.reinicia(msg);
      this.setState({linha: '',
      nroWhatsapp: '',
      indicacaoObs: '',
      nmCliente: '',
      envio: '',
      estado: 1,
      url_cliente_referral: '', 
      ClientWhatsapp: '',
      });
      try {
        if (document.getElementById("nroWhatsapp"))
          document.getElementById("nroWhatsapp").value = '';
        if (document.getElementById("indicacaoObs"))
          document.getElementById("indicacaoObs").value = '';
        if (document.getElementById("frmReferral"))
          document.getElementById("frmReferral").reset();
      }
      catch(msg) {
        console.log(msg);
      }
  }

  changeHandler = event => {

    if (event===undefined || event.target===undefined)
      this.setState({ 'nroWhatsapp': event });
    else
      this.setState({ [event.target.id]: event.target.value });
  };
  

  async saveReferral(clientName, ClientWhatsapp, productsReferral, obs, linha) {
    try {
      const myReferral = {
        date: new Date().toISOString(),
        referralPartnerId: this.props.userData.userId,
        clientName: 'N/D',
        clientPhone: ClientWhatsapp.replace('(','').replace(')','').replace(' ','').replace('-','').replace('+',''),
        linha: linha,
        obs: (obs) ? obs : 'N/D',
        clientDiscount: this.props.userData.clientDiscount,
        rewardPercentage: this.props.userData.rewardPercentage,
        clientBonus: this.props.userData.clientBonus
      }
      //insert Referral
      var newReferral;
      var ReferralID;
      newReferral = await API.graphql(graphqlOperation(mutations.createReferral, {input: myReferral}));
      ReferralID = newReferral.data.createReferral.id;

      //insert ReferralProducts
      console.log(productsReferral);
      for (var i=0; i<productsReferral.length; i++) {
          var ReferralProductsInput = { referralProductsProductId: productsReferral[i].id,
                                    referralProductsReferralId: ReferralID}
          API.graphql(graphqlOperation(mutations.createReferralProducts, {input: ReferralProductsInput})).then(ReferralProduct => {console.log(ReferralProduct);})
          .catch(err => {console.log(err)});
      }

      //show the link
      var url_cliente_referral = 'https://desconto.minhacintamodeladora.com.br/?referralid=' + ReferralID;

      console.log('url: ' + url_cliente_referral)

      this.setState({      
          url_cliente_referral: url_cliente_referral,
          ClientWhatsapp: ClientWhatsapp,
          estado: 2
      })

    }
    catch (err) {
      return(err);
    }
  }
 
  //envia indicação por Sms
  enviaSMS(msg, celular) {
      // Load the AWS SDK for Node.js
      var AWS = require('aws-sdk');

      // Inicializar o provedor de credenciais do Amazon Cognito
      AWS.config.region = 'us-east-1'; // Região
      AWS.config.credentials = new AWS.CognitoIdentityCredentials({
          IdentityPoolId: 'us-east-1:60b64843-045d-4e43-9b1a-fd2576f153e1',
      });    //    AWS.config.update({region: 'us-east-1', credentials: myCredentials});

      // Create publish parameters
      console.log(celular);
      var params = {
        Message: msg, /* required */
        PhoneNumber: celular,
      };

      // Create promise and SNS service object
      var publishTextPromise = new AWS.SNS({apiVersion: '2010-03-31'}).publish(params).promise();

      // Handle promise's fulfilled/rejected states
      publishTextPromise.then(
        function(data) {
          console.log("MessageID is " + data.MessageId);
        }).catch(
          function(err) {
          console.error(err, err.stack);
        });
  }

  render() {
    if (this.state.estado===2)
      return(
        <section id="mcm-confirmacao" className="p-2">
            <div className="p-2 text-dark">Etapa Final</div>
            <p>Envie para seu cliente e peça para ele apresentar na loja para ganhar o desconto:</p>
            <div><p><LoaderButton
                  block
                  variant="success"
                  size="lg"
                  type="button"
                  text="WhatsAPP"
                  onClick={() => {
                    window.open("https://api.whatsapp.com/send?phone=" + this.state.ClientWhatsapp + "&text=" + encodeURI(this.state.url_cliente_referral));
                  }}
                /></p></div>
            <div><p><LoaderButton
                  block
                  variant="info"
                  size="lg"
                  type="button"
                  text="SMS"
                  onClick={() => {
                    this.enviaSMS(this.state.url_cliente_referral, this.state.nroWhatsapp);
                    alert('Enviado com sucesso!');
                  }}
                /></p></div>
            <div>
                <p>{this.state.url_cliente_referral}
                <LoaderButton
                  block
                  variant="secondary"
                  size="lg"
                  type="button"
                  text="Copiar"
                  onClick={() => {
                    navigator.clipboard.writeText(this.state.url_cliente_referral);
                    alert('Copiado para área de transferência!');
                  }}
                />
                </p>
            </div>
            <div>
            <p><LoaderButton
                  block
                  variant="outline-danger"
                  size="lg"
                  type="button"
                  text="Finalizar"
                  onClick={() => {
                    this.zeraForm();
                  }}
                />
            </p>
            </div>
      </section>
      );
    else
    return(
      <section
        id="mcm-confirmacao"
        style={{display:'block'}}
      >
          <div>Produtos Selecionados</div>
          <div>
            {this.props.produtos===null 
            ? <span>Nenhum Produto Selecionado</span>
            : <SelectedProducts indicacoes={this.props.produtos} showButton={false} />
            }
          </div>
          <section id="formulario">
          <Form className="needs-validation"
                onSubmit={this.submitHandler}
                noValidate
                id="frmReferral"
                autoComplete="off"
                >
            <FormGroup controlId="alinhagrp">
              <FormLabel className="text-dark sm">Compressão</FormLabel><br />
              <FormCheck type="radio" name="alinha" size="sm" label="Menor (Soft)"  id="linhasoft" inline="true" />
              <FormCheck type="radio" name="alinha" size="sm" label="Maior (Premium)" defaultChecked id="linhapremium" inline="true" />
            </FormGroup>
            <FormGroup controlId="nroWhatsapp">
              <PhoneInput
                  autoComplete="off"
                  defaultCountry="BR"
                  placeholder="Celular do Cliente com DDD"
                  value={this.state.nroWhatsapp}
                  onChange={this.changeHandler}
                  limitMaxLength = {true}

                  />
            </FormGroup>
            <FormGroup controlId="indicacaoObs">
              <FormControl
                size="lg"
                type="text"
                className="form-control"
                value={this.state.indicacaoObs}
                onChange={this.changeHandler}
                placeholder="Observações"
              />
            </FormGroup>
            <FormGroup controlId="nmCliente">
              <FormControl
                size="lg"
                type="text"
                className="form-control"
                value="sem nome" //{this.state.nmCliente}
                onChange={this.changeHandler}
                placeholder="Nome Cliente"
              />
            </FormGroup>
            <LoaderButton
              block
              variant="secondary"
              size="lg"
              type="submit"
              text="Próxima Etapa >"
            />
            <LoaderButton
              block
              variant="outline-secondary"
              size="lg"
              type="button"
              text="Cancelar"
              onClick={() => {                
              this.props.reinicia('');
              }}
            />
            <span id="msgerro"></span>
            </Form>
          </section>
          <section
            id="mcm-confirmacao-envio"
            style={{display:'none'}}
          >
            <span id="msg-pos-envio"></span>
          </section>
      </section>

    );
  }

}
export default MyReferral;
