import React, { Component }  from "react";

class SelectedProducts extends Component {
    
    constructor(props) {
        super(props);
    
        this.state = {
        };
//        console.log(props.indicacoes);
        if (props.indicacoes!==null) {
            if (props.indicacoes.length>0) {
                document.getElementById('dica').style.display = "none";
                if (props.showButton) {    
                    document.getElementById('btnIndicar').style.display = "inline";
                    document.getElementById('btnLimpar').style.display = "inline";
                }
            }
        }
    }
    
    componentDidMount = () => {
        //        console.log('componetDidUpdate');
        //        console.log(this.props.indicacoes);   
                if (this.props.indicacoes!==null) {
                    if (this.props.indicacoes.length>0) {
                        document.getElementById('dica').style.display = "none";
                        if (this.props.showButton) {    
                            document.getElementById('btnIndicar').style.display = "inline";
                            document.getElementById('btnLimpar').style.display = "inline";
                        }
                    }
                    for (var i=0 ; i<this.props.indicacoes.length ; i++) {                        
                        document.getElementById('imgsel'+i).src = this.props.indicacoes[i].path;
                        document.getElementById('imgsel'+i).alt = this.props.indicacoes[i].ref;
                        document.getElementById('imgsel'+i).style.display = "inline";
                    }
                }
            }
        
    componentDidUpdate = (prevProps) => {
//        console.log('componetDidUpdate');
//        console.log(this.props.indicacoes);   
        if (this.props.indicacoes!==null) {
            if (this.props.indicacoes.length>0) {
                document.getElementById('dica').style.display = "none";
                if (this.props.showButton) {    
                    document.getElementById('btnIndicar').style.display = "inline";
                    document.getElementById('btnLimpar').style.display = "inline";
                }
            }
            for (var i=0 ; i<this.props.indicacoes.length ; i++) {
                document.getElementById('imgsel'+i).src = this.props.indicacoes[i].path;
                document.getElementById('imgsel'+i).alt = this.props.indicacoes[i].ref;
                document.getElementById('imgsel'+i).style.display = "inline";
            }
        }
    }
 
    render() {
        return(
            <div className="w-100 p-1">
                <span id="dica">Clique nos produtos que deseja Indicar</span>
                <button
                    id="btnLimpar"
                    className="LoaderButton btn btn-outline-secondary btn-lg"
                    style={{display:'none',float:'left'}}
                    onClick={() => {
                        this.props.callbackLimpar();
                    }}
                >
                    Limpar
                </button>
                <img id="imgsel0" key="imgsel0" src="" alt="Produto Selecionado 1" width="30px" height="45px" style={{display:'none'}}/>
                <img id="imgsel1" key="imgsel1" src="" alt="Produto Selecionado 2" width="30px" height="45px" style={{display:'none'}}/>
                <img id="imgsel2" key="imgsel2" src="" alt="Produto Selecionado 3" width="30px" height="45px" style={{display:'none'}}/>
                <img id="imgsel3" key="imgsel3" src="" alt="Produto Selecionado 4" width="30px" height="45px" style={{display:'none'}}/>
                <button
                    id="btnIndicar"
                    className="LoaderButton btn btn-secondary btn-lg"
                    style={{display:'none',float:'right'}}
                    onClick={() => {
                        this.props.callbackIndicar();
                    }}                
                >
                    Indicar
                </button>
            </div>
        );
    }
}

export default SelectedProducts;
