import React from 'react';
import awsmobile from '../aws-exports';
import { Container, ListGroup } from 'react-bootstrap';
import { getProductByReference } from '../data/Products';
import './MyReferralDetail.css';
import MyReferralDiscount from './MyReferralDiscount';
import { Auth } from 'aws-amplify';
import { API } from "aws-amplify";
import * as queries from './../graphql/queries.ts';
import ProductsCards from './ProductsCards';

//Passar referralId
class MyReferralDetail extends React.Component {

  prazoValidade = 90; //em dias

  constructor(props) {
		super(props);
    this.state = {parametroReferral: null};
	}

  async componentDidMount() {
    try {
      this.loadReferralData(this.props.referralId);
    } catch (e) {
        alert(e);
    }
  }


  addDays(date, days) {
    const copy = new Date(date);
    copy.setDate(copy.getDate() + days);
    return copy;
  }


  loadReferralData = (referralId) => {
    API.configure(awsmobile);
    Auth.configure(awsmobile);
    Auth.currentCredentials()
      .then(anonymousUser => console.log('Credential Ok'))
      .catch(e => console.log('error: ', e));

    //console.log(referralId);
    API.graphql({
      query: queries.getReferral2,
      variables: { id: referralId },
      authMode: 'AWS_IAM'
      }).then(myReferral => {
        //console.log(myReferral);
        var arrProducts = [];
        for (var i=0;i<myReferral.data.getReferral.products.items.length;i++) {
             arrProducts.push(getProductByReference(myReferral.data.getReferral.products.items[i].product.reference)[0]);
        }
        this.setState({products: arrProducts, parametroReferral : myReferral.data.getReferral});

      }).catch(err => {console.log(err);});
  }

  render() {

    if (this.state.parametroReferral) {
      var dtIndicacao = new Date (this.state.parametroReferral.date);
      var dtValidade = this.addDays(this.state.parametroReferral.date, this.prazoValidade);
      var dtHoje = new Date();
      var vencido = dtValidade < dtHoje;

//      s(dtIndicacao, dtValidade, dtHoje, vencido);
  
      return(

      <Container>
      <MyReferralDiscount myReferral={this.state.parametroReferral} descontoUtilizado={this.props.descontoUtilizado} setRewardPercentage={this.props.setRewardPercentage} vencido={vencido}/>        

      <ListGroup>
        <ListGroup.Item variant="light">Data de validade: <h5>{dtValidade.toLocaleString('pt-BR')}</h5></ListGroup.Item>
        <ListGroup.Item variant="light">Data da indicação: <h5>{dtIndicacao.toLocaleString('pt-BR')}</h5></ListGroup.Item>
        <ListGroup.Item variant="light">Telefone: <h5>{this.state.parametroReferral.clientPhone}</h5></ListGroup.Item>
        <ListGroup.Item variant="light">Linha: <h5>{this.state.parametroReferral.linha}</h5></ListGroup.Item>
        <ListGroup.Item variant="dark">Obs:</ListGroup.Item>
        <ListGroup.Item variant="light"><h5>{this.state.parametroReferral.obs}</h5></ListGroup.Item>
        <ListGroup.Item variant="dark">Produtos:</ListGroup.Item>
      </ListGroup>
      <ProductsCards products={this.state.products}></ProductsCards>
    </Container>
      );
    }
    else
      return('');
        
  }

}
export default MyReferralDetail;
