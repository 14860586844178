import { createSlice, PayloadAction } from '@reduxjs/toolkit';
import { IPartnerDTO } from './../models/partner';

const initialState: IPartnerDTO = {
    id: '',
    sub: '',
    email: '',
    type: 'Ext',
    name: '',
    rewardPercentage: 0,
    clientDiscount: 0,
    clientBonus: ''
}

export const partnerSlice:any = createSlice({
  name: 'partner',
  initialState: initialState,
  reducers: {    
    SET_PARTNER: (state, action: PayloadAction<IPartnerDTO>) => (        
        state = { ...action.payload }
    )
  }
});

export const { SET_PARTNER } = partnerSlice.actions;
export default partnerSlice.reducer    
