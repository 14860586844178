import React, { Component } from 'react';
import { Alert, FormGroup, FormControl } from 'react-bootstrap';
import LoaderButton from '../components/LoaderButton';
import { Auth } from 'aws-amplify';
import './Login.css';
import SignupConfirmation from './../components/SignupConfirmation';

export default class Login extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			email: '',
			password: '',
			showAlert: false,
			msg: '',
			userNotConfirmed: undefined
		};
	}

	validateForm() {
		return this.state.email.length > 0 && this.state.password.length > 0;
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};
	
	handleSubmit = async event => {
		event.preventDefault();
		this.setState({ isLoading: true });
		try {						
			const user = await Auth.signIn(this.state.email, this.state.password);						
			await this.props.userHasAuthenticated(true, user);			
			this.props.history.push('/');			
		} catch (error) {			
			if (error.code === 'UserNotConfirmedException') {
				this.setState({userNotConfirmed: true});
				return;
			}
			alert('Erro de Login: (' + error.message + ')');					
			this.setState({ isLoading: false });
		} 
	};
	
	setUserConfirmed = () =>  {
		this.setState({ userNotConfirmed : false});
	}

	render() {
			return (				
				(!this.state.userNotConfirmed?					
					<div className="Login">
						<form onSubmit={this.handleSubmit}>
							<h2>Efetue o login</h2>
							<FormGroup controlId="email">
								<FormControl autoFocus type="email" value={this.state.email} onChange={this.handleChange} placeholder="Seu Email aqui"/>
							</FormGroup>
							<FormGroup controlId="password">
								<FormControl value={this.state.password} onChange={this.handleChange} type="password" placeholder="Sua senha aqui"/>
							</FormGroup>
							<p>Esqueceu a senha? <a href="/forgotpassword">Clique aqui</a></p>
							<LoaderButton
								block
								variant="secondary"
								size="lg"
								disabled={!this.validateForm()}
								type="submit"
								isLoading={this.state.isLoading}
								text="Entrar"
								loadingText="Entrando…"
							/>
							<Alert className="mt-2" show={this.state.showAlert} variant='danger'>
								<p>
								{this.state.msg}
								</p>
							</Alert>

							<p>Ainda não tem cadastro? <a href="/signup">Cadastre-se aqui</a></p>
						</form>					
					</div>
				:
					<div className="Login">
						<SignupConfirmation email={this.state.email} setUserConfirmed={this.setUserConfirmed}></SignupConfirmation>
					</div>
				)
			);
	}
}
