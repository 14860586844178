import { Nav, Navbar, Dropdown, DropdownButton } from 'react-bootstrap';
import { LinkContainer } from 'react-router-bootstrap';
import React, { Fragment, Component } from 'react';
import imgLogomcm from './../img/MCM_Logos-01.png';

 class Header extends Component {    

    render () {        
        return(    
        <Navbar bg="light" variant="light" expand="lg" collapseOnSelect>
        <LinkContainer to="/">                              
            <Navbar.Brand ><img src={imgLogomcm} alt="Logo Minha Cinta Modeladora" height="35px" /></Navbar.Brand>
        </LinkContainer>

        <DropdownButton id="cmbUserFilter" variant="light" title={this.props.userData.filter_label} onSelect={this.props.userData.handleFilter}>
        <Dropdown.Item eventKey="todas">Todas</Dropdown.Item>
        <Dropdown.Item eventKey="premium">Premium</Dropdown.Item>
        <Dropdown.Item eventKey="soft">Soft</Dropdown.Item>
        <Dropdown.Item eventKey="masculino">Masculino</Dropdown.Item>
        <Dropdown.Item eventKey="outras">Outras</Dropdown.Item>
        <Dropdown.Item eventKey="acessorio">Acessórios</Dropdown.Item>
        <Dropdown.Item eventKey="sutia">Sutiãs</Dropdown.Item>
        </DropdownButton>

        <Navbar.Toggle aria-controls="responsive-navbar-nav" />
        <Navbar.Collapse id="responsive-navbar-nav">
        <Nav className="justify-content-end">
            {(this.props.userData.isAuthenticated ?  

                (((this.props.userData.userType || 'Ext') === "IntAd")  ? 

                    <Fragment>                    
                        <Nav.Link className="link-do-menu" href="" onClick={this.props.userData.handleLogout}>Logout ({this.props.userData.userEmail})</Nav.Link>
                        <LinkContainer to="/relsale">                              
                            <Nav.Link className="link-do-menu">Histórico de Influência</Nav.Link>							
                        </LinkContainer>
                        <LinkContainer to="/sale">                              
                            <Nav.Link className="link-do-menu">Vendas</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/partners">                              
                            <Nav.Link className="link-do-menu">Parceiros</Nav.Link>
                        </LinkContainer>
                        <LinkContainer to="/payment">                              
                            <Nav.Link className="link-do-menu">Pagamentos</Nav.Link>
                        </LinkContainer>
                        {(this.props.userData.subscription === undefined ? 
                            <Nav.Link data-userid={this.props.userData.userId} id="enabnot" eventKey="enableNotificationsLink" className="link-do-menu " href="" onClick={this.handleNotifications}>Habilitar Notificações</Nav.Link>
                        :
                            <Nav.Link data-userid={this.props.userData.userId} id="enabnot" eventKey="disableNotificationsLink" className="link-do-menu " href="" onClick={this.handleDisableNotifications}>Desabilitar Notificações</Nav.Link>
                        )}
                        <LinkContainer to="/about">                              
                            <Nav.Link className="link-do-menu">Sobre</Nav.Link>
                        </LinkContainer>
                        <Nav.Link className="link-do-menu" href="" onClick={this.deleteCache}>Atualizar App</Nav.Link>
                    </Fragment>

                : 
                    (((this.props.userData.userType || 'Ext') === "IntOp") ? 
                        <Fragment>
                            <Nav.Link className="link-do-menu" href="" onClick={this.props.userData.handleLogout}>Logout ({this.props.userData.userEmail})</Nav.Link>
                            <LinkContainer to="/relsale">                              
                                <Nav.Link className="link-do-menu">Histórico de Influência</Nav.Link>							
                            </LinkContainer>
                            <LinkContainer to="/sale">                              
                                <Nav.Link className="link-do-menu">Vendas</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/partners">                              
                                <Nav.Link className="link-do-menu">Parceiros</Nav.Link>
                            </LinkContainer>
                            {(this.props.userData.subscription === undefined ? 
                            <Nav.Link data-userid={this.props.userData.userId} id="enabnot" eventKey="enableNotificationsLink" className="link-do-menu " href="" onClick={this.handleNotifications}>Habilitar Notificações</Nav.Link>
                            :
                            <Nav.Link data-userid={this.props.userData.userId} id="enabnot" eventKey="disableNotificationsLink" className="link-do-menu " href="" onClick={this.handleDisableNotifications}>Desabilitar Notificações</Nav.Link>
                            )}
                            <LinkContainer to="/about">                              
                                <Nav.Link className="link-do-menu">Sobre</Nav.Link>
                            </LinkContainer>
                            <Nav.Link className="link-do-menu" href="" onClick={this.deleteCache}>Atualizar App</Nav.Link>
                        </Fragment>
                    :
                        <Fragment>
                            <Nav.Link className="link-do-menu" href="" onClick={this.props.userData.handleLogout}>Logout ({this.props.userData.userEmail})</Nav.Link>
                            <LinkContainer to="/relsale">                              
                                <Nav.Link className="link-do-menu">Histórico de Influência</Nav.Link>							
                            </LinkContainer>						
                            {(this.props.userData.subscription === undefined ? 
                            <Nav.Link data-userid={this.props.userData.userId} id="enabnot" eventKey="enableNotificationsLink" className="link-do-menu" href="" onClick={this.handleNotifications}>Habilitar Notificações</Nav.Link>
                            :
                            <Nav.Link data-userid={this.props.userData.userId} id="enabnot" eventKey="disableNotificationsLink" className="link-do-menu" href="" onClick={this.handleDisableNotifications}>Desabilitar Notificações</Nav.Link>
                            )}
                            <LinkContainer to="/about">                              
                                <Nav.Link className="link-do-menu">Sobre</Nav.Link>
                            </LinkContainer>
                            <Nav.Link className="link-do-menu" href="" onClick={this.deleteCache}>Atualizar App</Nav.Link>
                        </Fragment>
                        )
                    )
                :
                    <Fragment>
                            <LinkContainer to="/signup">                              
                                <Nav.Link className="link-do-menu">Cadastre-se</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/login">                              
                                <Nav.Link className="link-do-menu">Entrar</Nav.Link>
                            </LinkContainer>
                            <LinkContainer to="/about">                              
                                <Nav.Link className="link-do-menu">Sobre</Nav.Link>
                            </LinkContainer>
                            <Nav.Link className="link-do-menu" href="" onClick={this.deleteCache}>Atualizar App</Nav.Link>
                    </Fragment>
                )}
        </Nav>
        </Navbar.Collapse>
        </Navbar>
        );
    }
}
export default Header;