import React from 'react';
import MySwiper from '../components/MySwiper';
import { products } from '../data/Products';

export default class Catalog extends React.Component {

  imgs = products;

  constructor(props) {
      super(props);

      this.state = {
        images : this.imgs
      };

  }


  _onImageClick(event) {
    console.debug('clicked on image', event.target, 'at index', this._imageGallery.getCurrentIndex());
  }


  componentDidUpdate = (prevProps) => {

    if (this.props.userData.userFilter !== prevProps.userData.userFilter)
      {
        var filtro = this.props.userData.userFilter;
        const img_filtered = this.imgs.filter(function(a) {
          return (a.linha === filtro  || filtro === "todas" || a.tag === filtro);
        });
        this.setState({images : img_filtered});
      }
  }

  render() {
      return (
      <div>
        <MySwiper imgs={this.state.images} userData={this.props.userData} />
      </div>
    );
  }  
}