import React, { Component } from 'react';
import Catalog from '../components/Catalog';
import './Home.css';

export default class Home extends Component {
	constructor(props) {
		super(props);


		this.state = {
			isLoading: true
		};
	}

	async componentDidMount() {
		if (!this.props.isAuthenticated) {
			return;
		}
		this.setState({ isLoading: false });
	}

	render() {
		const userData = {
			isAuthenticated: this.props.isAuthenticated,
			isAuthenticating: this.props.isAuthenticating,
			userId: this.props.userId,
	    	userSub : this.props.userSub,
  			userEmail :this.props.userEmail,
  		    userType: this.props.userType,
			userFilter: this.props.userFilter,
			rewardPercentage: this.props.rewardPercentage,
			clientDiscount: this.props.clientDiscount,
			clientBonus: this.props.clientBonus
		};
		return(
			<div className="Home">
				<div className="lander">
					<Catalog userData={userData} />
				</div>
			</div>
		);
	}
}
