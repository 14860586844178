import React, { Component } from 'react';
import { FormText, FormGroup, FormControl } from 'react-bootstrap';
import LoaderButton from '../components/LoaderButton';
import { Auth } from 'aws-amplify';
import './Signup.css';

export default class ForgotPassword extends Component {
	constructor(props) {
		super(props);

		this.state = {
			isLoading: false,
			email: '',
			newpassword: '',
			confirmationCode: '',
      forgetPwd: null
		};
	}

	validateForm() {
		return (
			this.state.email.length > 0
		);
	}

	validateConfirmationForm() {
		return this.state.confirmationCode.length > 0;
	}

	handleChange = event => {
		this.setState({
			[event.target.id]: event.target.value
		});
	};

	handleSubmit = async event => {
		event.preventDefault();

		this.setState({ isLoading: true });

		try {
			const forgetPwd = await Auth.forgotPassword(this.state.email);
			this.setState({
				forgetPwd
			});
		} catch (e) {
			alert(e.message);
		}

		this.setState({ isLoading: false });
	};

	handleConfirmationSubmit = async event => {
		event.preventDefault();

		this.setState({ isLoading: true });

		try {
			await Auth.forgotPasswordSubmit(this.state.email, this.state.confirmationCode, this.state.newPassword);
			await Auth.signIn(this.state.email, this.state.newPassword);

			this.props.userHasAuthenticated(true);
			this.props.history.push('/');
		} catch (e) {
			alert(e.message);
			this.setState({ isLoading: false });
		}
	};

	renderConfirmationForm() {
		return (
			<form onSubmit={this.handleConfirmationSubmit}>
				<FormGroup controlId="confirmationCode">
					<FormControl autoFocus type="tel" value={this.state.confirmationCode} onChange={this.handleChange} placeholder="Código de confirmação aqui"/>
					<FormText>Por favor, verifique o código no seu email.</FormText>
				</FormGroup>
        <FormGroup controlId="newPassword">
					<FormControl autoFocus type="password" value={this.state.newPassword} onChange={this.handleChange} placeholder="Nova Senha aqui"/>
					<FormText>Por favor, digite a nova senha.</FormText>
				</FormGroup>
				<LoaderButton
					block
					disabled={!this.validateConfirmationForm()}
					type="submit"
					isLoading={this.state.isLoading}
					text="Redefinir"
					loadingText="Redefinindo…"
				/>
			</form>
		);
	}

	renderForm() {
		return (
			<form onSubmit={this.handleSubmit}>
				<h2>Redefina sua Senha</h2>
				<FormGroup controlId="email">
					<FormControl autoFocus type="email" value={this.state.email} onChange={this.handleChange} placeholder="Seu Email Aqui"/>
				</FormGroup>
				<LoaderButton
					block
					variant="secondary"
					size="lg"
					disabled={!this.validateForm()}
					type="submit"
					isLoading={this.state.isLoading}
					text="Enviar Código"
					loadingText="Processando…"
				/>
			</form>
		);
	}

	render() {
		return (
			<div className="Signup">{this.state.forgetPwd === null ? this.renderForm() : this.renderConfirmationForm()}</div>
		);
	}
}
