import React from 'react';
/*
name: control name
[class]: class name
onChange: method to execute
label: (Ex.: "Selecione o parceiro")
options: array com itens da lista e seguinte interface:
            {id: option value,
            text: option text}
*/
export const CustomDropdown = (props) => (
    <select
      className={props.class?props.class:"form-group"}
      name={props.name}
      id={props.name}
      onChange={props.onChange}      
    >
      <option defaultValue={0}>{props.label?props.label:"Selecione ->"}</option>
      {props.options.map((item) => (
        <option key={item.id} value={item.id}>
          {item.text}
        </option>
      ))}
    </select>
)

export default CustomDropdown;
