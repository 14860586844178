// tslint:disable
// this is an auto generated file. This will be overwritten

export const createPartner = `mutation CreatePartner($input: CreatePartnerInput!) {
  createPartner(input: $input) {
    id
    sub
    name
    phone
    email
    birthday
    gender
    referrals {
      items {
        id
        date
        clientName
        clientPhone
        linha
        obs
        rewardPercentage
        clientDiscount
        clientBonus
      }
      nextToken
    }
    type
    salesman {
      id
      name
      mainstore {
        id
        name
        address
        phone
        whatsapp
        email
        obs
      }
      active
      mylogin {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
    }
    rewardPercentage
    clientDiscount
    clientBonus
  }
}
`;
export const updatePartner = `mutation UpdatePartner($input: UpdatePartnerInput!) {
  updatePartner(input: $input) {
    id
    sub
    name
    phone
    email
    birthday
    gender
    referrals {
      items {
        id
        date
        clientName
        clientPhone
        linha
        obs
        rewardPercentage
        clientDiscount
        clientBonus
      }
      nextToken
    }
    type
    salesman {
      id
      name
      mainstore {
        id
        name
        address
        phone
        whatsapp
        email
        obs
      }
      active
      mylogin {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
    }
    rewardPercentage
    clientDiscount
    clientBonus
  }
}
`;
export const deletePartner = `mutation DeletePartner($input: DeletePartnerInput!) {
  deletePartner(input: $input) {
    id
    sub
    name
    phone
    email
    birthday
    gender
    referrals {
      items {
        id
        date
        clientName
        clientPhone
        linha
        obs
        rewardPercentage
        clientDiscount
        clientBonus
      }
      nextToken
    }
    type
    salesman {
      id
      name
      mainstore {
        id
        name
        address
        phone
        whatsapp
        email
        obs
      }
      active
      mylogin {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
    }
    rewardPercentage
    clientDiscount
    clientBonus
  }
}
`;
export const createReferral = `mutation CreateReferral($input: CreateReferralInput!) {
  createReferral(input: $input) {
    id
    date
    partner {
      id
      sub
      name
      phone
      email
      birthday
      gender
      referrals {
        nextToken
      }
      type
      salesman {
        id
        name
        active
      }
      rewardPercentage
      clientDiscount
      clientBonus
    }
    clientName
    clientPhone
    products {
      items {
        id
      }
      nextToken
    }
    linha
    order {
      id
      date
      referral {
        id
        date
        clientName
        clientPhone
        linha
        obs
        rewardPercentage
        clientDiscount
        clientBonus
      }
      clientName
      clientPhone
      clientCPF
      clientBirthday
      document
      amount
      store {
        id
        name
        address
        phone
        whatsapp
        email
        obs
      }
      salesman {
        id
        name
        active
      }
      obs
      rewardAmount
      payment {
        nextToken
      }
    }
    obs
    rewardPercentage
    clientDiscount
    clientBonus
  }
}
`;
export const updateReferral = `mutation UpdateReferral($input: UpdateReferralInput!) {
  updateReferral(input: $input) {
    id
    date
    partner {
      id
      sub
      name
      phone
      email
      birthday
      gender
      referrals {
        nextToken
      }
      type
      salesman {
        id
        name
        active
      }
      rewardPercentage
      clientDiscount
      clientBonus
    }
    clientName
    clientPhone
    products {
      items {
        id
      }
      nextToken
    }
    linha
    order {
      id
      date
      referral {
        id
        date
        clientName
        clientPhone
        linha
        obs
        rewardPercentage
        clientDiscount
        clientBonus
      }
      clientName
      clientPhone
      clientCPF
      clientBirthday
      document
      amount
      store {
        id
        name
        address
        phone
        whatsapp
        email
        obs
      }
      salesman {
        id
        name
        active
      }
      obs
      rewardAmount
      payment {
        nextToken
      }
    }
    obs
    rewardPercentage
    clientDiscount
    clientBonus
  }
}
`;
export const deleteReferral = `mutation DeleteReferral($input: DeleteReferralInput!) {
  deleteReferral(input: $input) {
    id
    date
    partner {
      id
      sub
      name
      phone
      email
      birthday
      gender
      referrals {
        nextToken
      }
      type
      salesman {
        id
        name
        active
      }
      rewardPercentage
      clientDiscount
      clientBonus
    }
    clientName
    clientPhone
    products {
      items {
        id
      }
      nextToken
    }
    linha
    order {
      id
      date
      referral {
        id
        date
        clientName
        clientPhone
        linha
        obs
        rewardPercentage
        clientDiscount
        clientBonus
      }
      clientName
      clientPhone
      clientCPF
      clientBirthday
      document
      amount
      store {
        id
        name
        address
        phone
        whatsapp
        email
        obs
      }
      salesman {
        id
        name
        active
      }
      obs
      rewardAmount
      payment {
        nextToken
      }
    }
    obs
    rewardPercentage
    clientDiscount
    clientBonus
  }
}
`;
export const createOrder = `mutation CreateOrder($input: CreateOrderInput!) {
  createOrder(input: $input) {
    id
    date
    referral {
      id
      date
      partner {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
      clientName
      clientPhone
      products {
        nextToken
      }
      linha
      order {
        id
        date
        clientName
        clientPhone
        clientCPF
        clientBirthday
        document
        amount
        obs
        rewardAmount
      }
      obs
      rewardPercentage
      clientDiscount
      clientBonus
    }
    clientName
    clientPhone
    clientCPF
    clientBirthday
    document
    amount
    store {
      id
      name
      address
      phone
      whatsapp
      email
      obs
      salesmans {
        nextToken
      }
    }
    salesman {
      id
      name
      mainstore {
        id
        name
        address
        phone
        whatsapp
        email
        obs
      }
      active
      mylogin {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
    }
    obs
    rewardAmount
    payment {
      items {
        id
      }
      nextToken
    }
  }
}
`;
export const updateOrder = `mutation UpdateOrder($input: UpdateOrderInput!) {
  updateOrder(input: $input) {
    id
    date
    referral {
      id
      date
      partner {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
      clientName
      clientPhone
      products {
        nextToken
      }
      linha
      order {
        id
        date
        clientName
        clientPhone
        clientCPF
        clientBirthday
        document
        amount
        obs
        rewardAmount
      }
      obs
      rewardPercentage
      clientDiscount
      clientBonus
    }
    clientName
    clientPhone
    clientCPF
    clientBirthday
    document
    amount
    store {
      id
      name
      address
      phone
      whatsapp
      email
      obs
      salesmans {
        nextToken
      }
    }
    salesman {
      id
      name
      mainstore {
        id
        name
        address
        phone
        whatsapp
        email
        obs
      }
      active
      mylogin {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
    }
    obs
    rewardAmount
    payment {
      items {
        id
      }
      nextToken
    }
  }
}
`;
export const deleteOrder = `mutation DeleteOrder($input: DeleteOrderInput!) {
  deleteOrder(input: $input) {
    id
    date
    referral {
      id
      date
      partner {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
      clientName
      clientPhone
      products {
        nextToken
      }
      linha
      order {
        id
        date
        clientName
        clientPhone
        clientCPF
        clientBirthday
        document
        amount
        obs
        rewardAmount
      }
      obs
      rewardPercentage
      clientDiscount
      clientBonus
    }
    clientName
    clientPhone
    clientCPF
    clientBirthday
    document
    amount
    store {
      id
      name
      address
      phone
      whatsapp
      email
      obs
      salesmans {
        nextToken
      }
    }
    salesman {
      id
      name
      mainstore {
        id
        name
        address
        phone
        whatsapp
        email
        obs
      }
      active
      mylogin {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
    }
    obs
    rewardAmount
    payment {
      items {
        id
      }
      nextToken
    }
  }
}
`;
export const createPayment = `mutation CreatePayment($input: CreatePaymentInput!) {
  createPayment(input: $input) {
    id
    date
    partner {
      id
      sub
      name
      phone
      email
      birthday
      gender
      referrals {
        nextToken
      }
      type
      salesman {
        id
        name
        active
      }
      rewardPercentage
      clientDiscount
      clientBonus
    }
    orders {
      items {
        id
      }
      nextToken
    }
    paymentAmount
    paymentDescription
    paymentObs
  }
}
`;
export const updatePayment = `mutation UpdatePayment($input: UpdatePaymentInput!) {
  updatePayment(input: $input) {
    id
    date
    partner {
      id
      sub
      name
      phone
      email
      birthday
      gender
      referrals {
        nextToken
      }
      type
      salesman {
        id
        name
        active
      }
      rewardPercentage
      clientDiscount
      clientBonus
    }
    orders {
      items {
        id
      }
      nextToken
    }
    paymentAmount
    paymentDescription
    paymentObs
  }
}
`;
export const deletePayment = `mutation DeletePayment($input: DeletePaymentInput!) {
  deletePayment(input: $input) {
    id
    date
    partner {
      id
      sub
      name
      phone
      email
      birthday
      gender
      referrals {
        nextToken
      }
      type
      salesman {
        id
        name
        active
      }
      rewardPercentage
      clientDiscount
      clientBonus
    }
    orders {
      items {
        id
      }
      nextToken
    }
    paymentAmount
    paymentDescription
    paymentObs
  }
}
`;
export const createStore = `mutation CreateStore($input: CreateStoreInput!) {
  createStore(input: $input) {
    id
    name
    address
    phone
    whatsapp
    email
    obs
    salesmans {
      items {
        id
        name
        active
      }
      nextToken
    }
  }
}
`;
export const updateStore = `mutation UpdateStore($input: UpdateStoreInput!) {
  updateStore(input: $input) {
    id
    name
    address
    phone
    whatsapp
    email
    obs
    salesmans {
      items {
        id
        name
        active
      }
      nextToken
    }
  }
}
`;
export const deleteStore = `mutation DeleteStore($input: DeleteStoreInput!) {
  deleteStore(input: $input) {
    id
    name
    address
    phone
    whatsapp
    email
    obs
    salesmans {
      items {
        id
        name
        active
      }
      nextToken
    }
  }
}
`;
export const createSalesman = `mutation CreateSalesman($input: CreateSalesmanInput!) {
  createSalesman(input: $input) {
    id
    name
    mainstore {
      id
      name
      address
      phone
      whatsapp
      email
      obs
      salesmans {
        nextToken
      }
    }
    active
    mylogin {
      id
      sub
      name
      phone
      email
      birthday
      gender
      referrals {
        nextToken
      }
      type
      salesman {
        id
        name
        active
      }
      rewardPercentage
      clientDiscount
      clientBonus
    }
  }
}
`;
export const updateSalesman = `mutation UpdateSalesman($input: UpdateSalesmanInput!) {
  updateSalesman(input: $input) {
    id
    name
    mainstore {
      id
      name
      address
      phone
      whatsapp
      email
      obs
      salesmans {
        nextToken
      }
    }
    active
    mylogin {
      id
      sub
      name
      phone
      email
      birthday
      gender
      referrals {
        nextToken
      }
      type
      salesman {
        id
        name
        active
      }
      rewardPercentage
      clientDiscount
      clientBonus
    }
  }
}
`;
export const deleteSalesman = `mutation DeleteSalesman($input: DeleteSalesmanInput!) {
  deleteSalesman(input: $input) {
    id
    name
    mainstore {
      id
      name
      address
      phone
      whatsapp
      email
      obs
      salesmans {
        nextToken
      }
    }
    active
    mylogin {
      id
      sub
      name
      phone
      email
      birthday
      gender
      referrals {
        nextToken
      }
      type
      salesman {
        id
        name
        active
      }
      rewardPercentage
      clientDiscount
      clientBonus
    }
  }
}
`;
export const createProduct = `mutation CreateProduct($input: CreateProductInput!) {
  createProduct(input: $input) {
    id
    reference
    description
    imgurl0
    imgurl1
    imgurl2
    material
    compression
    brand
    referral {
      items {
        id
      }
      nextToken
    }
  }
}
`;
export const updateProduct = `mutation UpdateProduct($input: UpdateProductInput!) {
  updateProduct(input: $input) {
    id
    reference
    description
    imgurl0
    imgurl1
    imgurl2
    material
    compression
    brand
    referral {
      items {
        id
      }
      nextToken
    }
  }
}
`;
export const deleteProduct = `mutation DeleteProduct($input: DeleteProductInput!) {
  deleteProduct(input: $input) {
    id
    reference
    description
    imgurl0
    imgurl1
    imgurl2
    material
    compression
    brand
    referral {
      items {
        id
      }
      nextToken
    }
  }
}
`;
export const createReferralProducts = `mutation CreateReferralProducts($input: CreateReferralProductsInput!) {
  createReferralProducts(input: $input) {
    id
    product {
      id
      reference
      description
      imgurl0
      imgurl1
      imgurl2
      material
      compression
      brand
      referral {
        nextToken
      }
    }
    referral {
      id
      date
      partner {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
      clientName
      clientPhone
      products {
        nextToken
      }
      linha
      order {
        id
        date
        clientName
        clientPhone
        clientCPF
        clientBirthday
        document
        amount
        obs
        rewardAmount
      }
      obs
      rewardPercentage
      clientDiscount
      clientBonus
    }
  }
}
`;
export const updateReferralProducts = `mutation UpdateReferralProducts($input: UpdateReferralProductsInput!) {
  updateReferralProducts(input: $input) {
    id
    product {
      id
      reference
      description
      imgurl0
      imgurl1
      imgurl2
      material
      compression
      brand
      referral {
        nextToken
      }
    }
    referral {
      id
      date
      partner {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
      clientName
      clientPhone
      products {
        nextToken
      }
      linha
      order {
        id
        date
        clientName
        clientPhone
        clientCPF
        clientBirthday
        document
        amount
        obs
        rewardAmount
      }
      obs
      rewardPercentage
      clientDiscount
      clientBonus
    }
  }
}
`;
export const deleteReferralProducts = `mutation DeleteReferralProducts($input: DeleteReferralProductsInput!) {
  deleteReferralProducts(input: $input) {
    id
    product {
      id
      reference
      description
      imgurl0
      imgurl1
      imgurl2
      material
      compression
      brand
      referral {
        nextToken
      }
    }
    referral {
      id
      date
      partner {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
      clientName
      clientPhone
      products {
        nextToken
      }
      linha
      order {
        id
        date
        clientName
        clientPhone
        clientCPF
        clientBirthday
        document
        amount
        obs
        rewardAmount
      }
      obs
      rewardPercentage
      clientDiscount
      clientBonus
    }
  }
}
`;
export const createPaymentOrders = `mutation CreatePaymentOrders($input: CreatePaymentOrdersInput!) {
  createPaymentOrders(input: $input) {
    id
    payment {
      id
      date
      partner {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
      orders {
        nextToken
      }
      paymentAmount
      paymentDescription
      paymentObs
    }
    order {
      id
      date
      referral {
        id
        date
        clientName
        clientPhone
        linha
        obs
        rewardPercentage
        clientDiscount
        clientBonus
      }
      clientName
      clientPhone
      clientCPF
      clientBirthday
      document
      amount
      store {
        id
        name
        address
        phone
        whatsapp
        email
        obs
      }
      salesman {
        id
        name
        active
      }
      obs
      rewardAmount
      payment {
        nextToken
      }
    }
  }
}
`;
export const updatePaymentOrders = `mutation UpdatePaymentOrders($input: UpdatePaymentOrdersInput!) {
  updatePaymentOrders(input: $input) {
    id
    payment {
      id
      date
      partner {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
      orders {
        nextToken
      }
      paymentAmount
      paymentDescription
      paymentObs
    }
    order {
      id
      date
      referral {
        id
        date
        clientName
        clientPhone
        linha
        obs
        rewardPercentage
        clientDiscount
        clientBonus
      }
      clientName
      clientPhone
      clientCPF
      clientBirthday
      document
      amount
      store {
        id
        name
        address
        phone
        whatsapp
        email
        obs
      }
      salesman {
        id
        name
        active
      }
      obs
      rewardAmount
      payment {
        nextToken
      }
    }
  }
}
`;
export const deletePaymentOrders = `mutation DeletePaymentOrders($input: DeletePaymentOrdersInput!) {
  deletePaymentOrders(input: $input) {
    id
    payment {
      id
      date
      partner {
        id
        sub
        name
        phone
        email
        birthday
        gender
        type
        rewardPercentage
        clientDiscount
        clientBonus
      }
      orders {
        nextToken
      }
      paymentAmount
      paymentDescription
      paymentObs
    }
    order {
      id
      date
      referral {
        id
        date
        clientName
        clientPhone
        linha
        obs
        rewardPercentage
        clientDiscount
        clientBonus
      }
      clientName
      clientPhone
      clientCPF
      clientBirthday
      document
      amount
      store {
        id
        name
        address
        phone
        whatsapp
        email
        obs
      }
      salesman {
        id
        name
        active
      }
      obs
      rewardAmount
      payment {
        nextToken
      }
    }
  }
}
`;
