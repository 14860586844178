import React from "react";

const Checkbox = ({ label, shortlabel, amount, comission, isSelected, onCheckboxChange }) => (
    <label>
      <input
        type="checkbox"
        name={shortlabel}
        checked={isSelected}
        onChange={onCheckboxChange}
        className="form-check-input"
      />
      {label}
      <input
        type="hidden"
        name= {'vl'+shortlabel}
        value={amount}
      />
      <input
        type="hidden"
        name= {'co'+shortlabel}
        value={comission}
      />
    </label>
);

export default Checkbox;