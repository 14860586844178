export function formatarData(data) {
  try {
    if (data.length===10)
      data=data+' 00:00'
//    console.log(data);
    var date = new Date(data);
//    console.log(date);  
    return(date.toLocaleDateString('pt-BR'));
//  console.log(fd);
  } catch(e) {
  console.log(e);
  }
}

export function maxlength(data, len) {
  try {
    var mystr = String(data);
    var retorno = '';
    var start=0;
    var end=len-1;
//    console.log(mystr);
    if (mystr.length>(end-start+1))
      retorno = mystr.substring(start,end-2)+'..'
    else
      retorno = mystr.substring(start,end);

    return(retorno);
  } catch(e) {
  console.log(e);
  }
}

export function formatarDataAlternativa(data) {
  try {
    var date = new Date(data);
    date.setHours(date.getHours() + 3);
    var dia  = date.getDate().toString().padStart(2, '0');
    var mes  = (date.getMonth()+1).toString().padStart(2, '0'); //+1 pois no getMonth Janeiro começa com zero.
    var ano  = date.getFullYear();
    
    return dia+"/"+mes+"/"+ano;

} catch(e) {
  console.log(e);
  }
}

export function dataAtualFormatada(){
  var data = new Date(),
      dia  = data.getDate().toString().padStart(2, '0'),
      mes  = (data.getMonth()+1).toString().padStart(2, '0'), //+1 pois no getMonth Janeiro começa com zero.
      ano  = data.getFullYear();
  return dia+"/"+mes+"/"+ano;
}
