import React, { useEffect, useState } from 'react';
import { Container, Row, Col, Form, FormGroup, FormControl } from 'react-bootstrap';
import * as queries from './../graphql/queries.ts';
import { API, graphqlOperation } from "aws-amplify";
import DataTable from 'react-data-table-component'; 
import PartnerDetail from '../components/PartnerDetail';

const SpinnerPage = () => {
  return (
    <>
      <div className="spinner-border" role="status">
        <span className="sr-only">Loading...</span>
      </div>
    </>
  );
}



const FilterComponent = ({ filterText, onFilter, onClear }) => (
<>
    <Container fluid>
        <Row>
            <Col className = 'p-1'>
                <Form>
                    <FormGroup controlId="search">
                        <FormControl
                        className="form-control"
                        type="text"
                        size="lg"
                        placeholder="Fitrar por nome ou email"
                        value={filterText} 
                        onChange={onFilter}
                        />
                    </FormGroup>
                </Form>
            </Col>
        </Row>
    </Container>
</>
);

const Partners = (props) => {
  const [isLoading, setIsLoading] = useState(true);
  const [data, setData] = useState([]);
  const [filterText, setFilterText] = React.useState('');
  const [resetPaginationToggle, setResetPaginationToggle] = React.useState(false);
  const [autenticado, setAutenticado] = useState(props.isAuthenticated);
  const [autorizado, setAutorizado] = useState(false);

//  const filteredItems = fakeUsers.filter(item => item.name && item.name.toLowerCase().includes(filterText.toLowerCase()));
  const filteredItems = data.filter(item => item.namemail && item.namemail.toLowerCase().includes(filterText.toLowerCase())); 
//    console.log(data, filteredItems);
  // unlike class methods updateState will be re-created on each render pass, therefore, make sure that callbacks passed to onSelectedRowsChange are memoized using useCallback
//  const updateState = useCallback(state => console.log(state),[]); 


//  const data = [{ id: 1, title: 'Conan the Barbarian', summary: 'Orphaned boy Conan is enslaved after his village is destroyed...',  year: '1982' },
//                { id: 2, title: 'ET', summary:'ET visited a little boy in Earth', year: '1980'}];
  const columns = [
    {
        name: 'Id',
        selector: 'id',
        sortable: true,
        left: true,
        allowOverflow: true,
        hide: 'md'
//        cell: row => <div data-tag="allowRowEvents"><div style={{ fontWeight: 'bold' }}>{row.title}</div>{row.summary}</div>,
    },
    {
        name: 'Nome / Email',
        selector: 'namemail',
        sortable: true,
        left: true,
        wrap: true
    },
    {
        name: 'Desc. Cliente',
        selector: 'clientDiscount',
        sortable: true,
        center: true,
        width: '80px'
    }];
    if (props.userType && props.userType==='IntAd')
        columns.push({
                name: 'Comissão',
                selector: 'rewardPercentage',
                sortable: true,
                center: true,      
                width: '80px'
            });

  useEffect(() => { 
        async function popData () {
              const myPartners = await API.graphql(graphqlOperation(queries.listPartners, {limit: 1000}));
//              console.log(myPartners);
              return(myPartners.data.listPartners.items);
        }


        if (props.userId) {
            if (props.userType==='IntOp' || props.userType==='IntAd') {
                if (!autorizado) {
                    setAutorizado(true);
                    setAutenticado(true);
                }
          
                const result = [];
                popData().then(partners => {
                    partners.forEach(item => {
                        if (item.type==="Ext") {
                            result.push({'id': item.id, 'namemail': (item.name ? item.name + ' / ' : '') + item.email, 'clientDiscount':Math.floor(item.clientDiscount*100)+'%', 'rewardPercentage': Math.floor(item.rewardPercentage*100)+'%' });
                        }
                    });
                    setData(result);
                    setIsLoading(false);
                }
                ).catch(msg => console.log(msg));
            }
            else
            {
                if (props.isAuthenticated) {
                    setAutenticado(true);
                    setAutorizado(false);
                }          
            }
        }
        
    },  [autenticado, autorizado, props.userId, props.userType, props.isAuthenticated] );

    const handleClick = (e) => {
            console.log(e);
    }

    const subHeaderComponentMemo = React.useMemo(() => {
        const handleClear = () => {
        if (filterText) {
            setResetPaginationToggle(!resetPaginationToggle);
            setFilterText('');
        }
        };

        return <FilterComponent onFilter={e => setFilterText(e.target.value)} onClear={handleClear} filterText={filterText} />;
    }, [filterText, resetPaginationToggle]);

    return (
        (autorizado ? 
            <div>
            <DataTable
            title='Parceiros'
            data={filteredItems.sort((a, b) => a.namemail.localeCompare(b.namemail))}
            columns={columns}
        //      onSelectedRowsChange={updateState}
        //      selectableRows
            striped={true}      
            responsive={true}
            fixedHeader={true}      
            onRowClicked={handleClick}
            progressPending={isLoading}
            progressComponent={<SpinnerPage />}
            expandableRows
            expandableRowDisabled={row => row.disabled}
            subHeader
            subHeaderComponent={subHeaderComponentMemo}        
            pagination
            paginationResetDefaultPage={resetPaginationToggle} 
            highlightOnHover
            expandableRowsComponent={<PartnerDetail userData={props} />}      
            />
        </div>    
        :
            (!autenticado ?
                <div className="mySaleContainer"><p>Verificando credenciais do usuário, aguarde</p></div>
            :
                <div className="mySaleContainer"><p>Acesso negado para usuário.</p></div>
            )
        )
    );
}

export default Partners;